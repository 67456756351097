<div class="flex flex-column justify-content-center mb-1">
    <div *ngIf="isLoading" class="spinner-container">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <p-toast [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'1000ms'"
        [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" />

    <h2 class="titleSection font-bold text-xl">Restablecer contraseña</h2>
    <form [formGroup]="emailForgotPassword" (ngSubmit)="forgotPasswordSendEmail()" *ngIf="!emailSend" class="col-10 p-0"
        id="container-form">
        <p class="titleSection font-normal text-xs">Ingrese su correo electrónico para poder restablecer su contraseña.
        </p>
        <div class="grid mt-1">
            <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column">
                <label class="labelTitle mb-1" for="email">Correo electrónico*</label>
                <input pInputText id="email" class="emailInput col-12" type="email" maxlength="40" autocomplete="off"
                    placeholder="Ingrese su correo electronico" formControlName="email" [ngClass]="{
                'ng-invalid ng-dirty':
                  hasErrors('email', 'required') || hasErrors('email', 'email')}" />
                <div class="text-danger p-error" *ngIf="hasErrors('email', 'required')">
                    Este campo es obligatorio
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('email', 'invalidEmail')">
                  El correo debe tener un formato válido
                </div>
            </div>
        </div>

        <p-button class="btnContinue nextButton" label="Continuar" [disabled]="emailForgotPassword.invalid"
            type="submit"></p-button>
        <span class="backLink"><a [routerLink]="['/auth/login']">Volver</a></span>
    </form>
    <div *ngIf="emailSend" class="information col-10">
        <img src="../../../../../../../assets/img/register/check-account.png" alt="">
        <span>Se envió un correo electrónico para reestablecer su contraseña.</span>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginUseCase } from '../../../domain/usecases/login.usercase';
import { Failure } from 'src/app/core/utils/failure';
import { Session } from '../../../domain/entities/session.entity';
import { SessionModel } from '../../../data/models/session.model';
import { AuthRepository } from '../../../data/repositories/auth.repository';
import { Message, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { UserService } from 'src/app/features/user/services/user.service';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthRepository, MessageService, Toast],
})
export class LoginComponent implements OnInit {
  messages: Message[] | undefined;
  formLogin: FormGroup;
  usuario: {} = {};
  showPassword: boolean = false;
  session: Session = new SessionModel('');
  isLoading: boolean = false;
  private AES = new AESEncryptDecryptService();
  public inputCheckbox!: boolean;

  constructor(
    private form: FormBuilder,
    private router: Router,
    private loginUseCase: LoginUseCase,
    private messageService: MessageService,
    private localStorageService: LocalStorageServiceBase,
    private userService: UserService,
    private getMyDataUseCase: GetMyDataUseCase
  ) {
    this.formLogin = this.form.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(40)],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(8),
        ],
      ],
      checkboxRememberMe: [false],
    });
  }

  ngOnInit() {
    this.isLogged();
    this.formLogin.controls['email'].valueChanges.subscribe((newValue: string) => {
      const savedEmail = this.AES.decrypt(this.localStorageService.loadStorageData('emailSave'));
      const shouldUncheck = newValue !== savedEmail;  
      this.inputCheckbox = !shouldUncheck;
      if (shouldUncheck) {
        const checkBox = document.getElementById('checkboxRememberMe') as HTMLInputElement;
        checkBox.checked = false;
      }
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formLogin.get(controlName)?.hasError(errorType) &&
      this.formLogin.get(controlName)?.touched
    );
  }

  async onLogin() {
    if (this.formLogin.invalid) return;
    let result: Session | Failure = await this.loginUseCase.execute(
      this.formLogin.value
    );

    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Los datos ingresados son incorrectos'
      });
    } else {
      this.session = result;
      this.localStorageService.save(result);
      this.getMe();
      await this.delay(500);
      this.isLoading = true;
      this.router.navigate(['/main/home']);
    }
  }

  async getMe(): Promise<void> {
    let result = await this.getMyDataUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userService.userData = result;
    this.userService.userData$.next(result);
    this.userService.userRol$.next(result.rol);
    this.localStorageService.saveStorageData('user_rol', this.AES.encrypt(result.rol));
    this.localStorageService.saveStorageData('user_id', this.AES.encrypt(result.id));
    this.localStorageService.saveStorageData('claims_quantity', result.claims?.length);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  savePass(event: Event) {
    this.inputCheckbox = (event.target as HTMLInputElement).checked;
    if (this.inputCheckbox && this.formLogin.controls['email'].value) {
      this.localStorageService.saveStorageData(
        'emailSave',
        this.AES.encrypt(this.formLogin.controls['email'].value),
      );
    } else {
      localStorage.clear();
    }
  }


  isLogged() {
    if (this.localStorageService.loadStorageData('emailSave')) {
      let checkBox = document.getElementById(
        'checkboxRememberMe',
      ) as HTMLInputElement;
      checkBox.checked = true;
      this.formLogin.controls['email'].setValue(
        this.AES.decrypt(this.localStorageService.loadStorageData('emailSave')),
      );
      this.inputCheckbox = true;
    }
  }

}

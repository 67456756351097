import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { CreateAudienceUseCase } from '../../../domain/usecases/create-audience.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { Router } from '@angular/router';
import { AudienceModel } from '../../../data/models/aucience.model';
import { CreateAudienceModel } from '../../../data/models/create-audience.model';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class AudienceComponent {
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @Output() showMessage = new EventEmitter<{ severity: string, summary: string, detail: string }>();
  @Input() claim?: ClaimEntity;

  time: Date[] | undefined;
  private AES = new AESEncryptDecryptService();
  minDate: Date = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageServiceBase,
    private createAudienceUseCase: CreateAudienceUseCase,
    private messageService: MessageService,
    private router: Router) { }

  ngOnChanges() {
    if (this.claim) {
      this.initializeFormWithClaimData();
    }
  }

  audienceForm: FormGroup = this.formBuilder.group({
    date: [, [Validators.required]],
    time: [, [Validators.required]],
    audienceTitle: [, [Validators.required]],   
    conciliatorFullName: [, [Validators.required]],   
    linkGoogleMeet: [],
    linkGoogleCalendar: []
  })

  cancel() {
    this.closePopup.emit(true);
  }

  initializeFormWithClaimData(): void {
    this.audienceForm.patchValue({
      conciliatorFullName: this.claim?.conciliatorFullName || 'No asignado',
    });
  }

  async createAudience(): Promise<void> {      
    let result = await this.createAudienceUseCase.execute(this.createNewAudience());
    if(result instanceof Failure) {
      this.showMessage.emit({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.showMessage.emit({
      severity: 'success',
      summary: 'Audiencia creada',
      detail: 'La audiencia se creó exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/claims']);
    }, 1000);
    this.cancel();
  }

 createNewAudience(): CreateAudienceModel {
    return new CreateAudienceModel(
      this.audienceForm.get('date')?.value,
      new Date(this.audienceForm.get('time')?.value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      this.audienceForm.get('audienceTitle')?.value,
      this.claim?.user_id!,
      'ClaimedID',
      this.claim?.id!,
    )
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.audienceForm.get(controlName)?.hasError(errorType) &&
      this.audienceForm.get(controlName)?.touched
    );
  }
}



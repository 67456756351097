import { Failure, UnhandledFailure } from "src/app/core/utils/failure";
import { CompanyPagination } from "../../domain/entities/company-pagination";
import { CompanyPaginationParams } from "../../domain/entities/company-pagination-params";
import { CompanyEntity } from "../../domain/entities/company.entity";
import { CompanyrepositoryBase } from "../../domain/repositories/company-base.repository";
import { CompanyRemoteDataSourceBase } from "../data-source/company-remote.datasource";
import { Injectable } from "@angular/core";
import { DeleteCompanyEntity } from "../../domain/entities/delete-company.entity";

@Injectable()
export class CompanyRepository extends CompanyrepositoryBase {

    constructor(private companyRemoteDataSource: CompanyRemoteDataSourceBase) {
        super();
    }

    override async getCompany(id: string): Promise<Failure | CompanyEntity> {
        try {
            return await this.companyRemoteDataSource.getCompany(id);
        } catch (error: any) {
            return new UnhandledFailure(error.error.message, error.code);
        };
    }

    override async getPaginatedCompanies(params: CompanyPaginationParams): Promise<Failure | CompanyPagination> {
        try {
            return await this.companyRemoteDataSource.getPaginatedCompanies(params);
        } catch (error: any) {
            return new UnhandledFailure(error.error.message, error.code);
        }
    }

    override async createCompany(company: CompanyEntity): Promise<string | Failure> {
        try {
            return await this.companyRemoteDataSource.createCompany(company);
        } catch (error: any) {
            return new UnhandledFailure(error.error.message, error.code);
        }
    }

    override async updateCompany(company: CompanyEntity): Promise<string | Failure> {
        try {
            return await this.companyRemoteDataSource.updateCompany(company);
        } catch (error: any) {
            return new UnhandledFailure(error.error.message, error.code);
        }
    }

    override async deleteCompany(deleteCompany: DeleteCompanyEntity): Promise<string | Failure> {
        try {
            return await this.companyRemoteDataSource.deleteCompany(deleteCompany);
        } catch (error: any) {
            return new UnhandledFailure(error.error.message, error.code);
        }
    }

}

<section class="flex justify-content-center align-items-center ">
    <article class="col-9 ">
        <div *ngIf="isLoading" class="spinner-container">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <p-toast [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'1000ms'"
        [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" />

        <form [formGroup]="newPasswordForgotForm" class="col-12 p-0" (ngSubmit)="changePassword()" *ngIf="!sendNewPAsswordOk">

            <span class="titleSection font-bold text-xl mt-2 mb-2">Restablecer contraseña</span>
            <div class="grid col-12">

              <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column mt-3">
                <label for="username" id="laber-span" class="labelTitle mb-1">Nueva contraseña *</label>
                <span class="p-input-icon-right">
                    <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                        (click)="togglePasswordVisibility()"></i>
                    <input pInputText id="newPassword" type="{{ showPassword ? 'text' : 'password' }}"
                        maxlength="32" placeholder="Ingrese la nueva contraseña" autocomplete="off"
                        formControlName="newPassword"
                        [ngClass]="{'input-error': newPasswordForgotForm.get('newPassword')?.invalid && newPasswordForgotForm.get('newPassword')?.touched}" />
                </span>
                <div class="p-error mt-1" *ngIf="newPasswordForgotForm.get('newPassword')?.hasError('invalidPassword') && newPasswordForgotForm.get('newPassword')?.touched">
                    La contraseña debe cumplir con todos los requisitos.
                </div>
            </div>

            <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column mt-3">
              <label for="repeatPassword" id="laber-span" class="labelTitle mb-1">Repetir nueva contraseña *</label>
              <span class="p-input-icon-right">
                  <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                      (click)="togglePasswordVisibility()"></i>
                  <input pInputText id="repeatPassword" type="{{ showPassword ? 'text' : 'password' }}" maxlength="32"
                      placeholder="Repita la nueva contraseña" autocomplete="off" formControlName="repeatedPassword"
                      [ngClass]="{'input-error': newPasswordForgotForm.get('repeatedPassword')?.invalid && newPasswordForgotForm.get('repeatedPassword')?.touched}" />
              </span>
              <div class="p-error mt-1" *ngIf="newPasswordForgotForm.hasError('mismatch') && newPasswordForgotForm.get('repeatedPassword')?.touched">
                  Las contraseñas no coinciden.
              </div>
          </div>

                <div class="col-12 flex flex-column">
                    <span class="mt-2 passValidators" id="uppercase"><i class="pi pi-check-circle"></i> Al menos un
                        carácter en
                        mayúscula</span>
                    <span class="mt-2 passValidators" id="lowercase"><i class="pi pi-check-circle"></i> Al menos un
                        carácter en
                        minúscula</span>
                    <span class="mt-2 passValidators" id="minlength"><i class="pi pi-check-circle"></i> Mínimo 8
                        caracteres</span>
                    <span class="mt-2 passValidators" id="number"><i class="pi pi-check-circle"></i> Mínimo un
                        número</span>
                    <span class="mt-2 passValidators" id="specialChar"><i class="pi pi-check-circle"></i> Mínimo un
                        carácter
                        especial</span>
                </div>

                <div class="col-12 flex flex-column">
                    <p-button class="nextButton" label="Cambiar contraseña" type="submit"
                     [disabled]="newPasswordForgotForm.invalid || newPasswordForgotForm.get('newPassword')?.hasError('invalidPassword')">
                    </p-button>
                    <span class="backLink"><a [routerLink]="['/auth/login']">Volver</a></span>
                </div>
            </div>
        </form>
        <div class="section" *ngIf="sendNewPAsswordOk" >
            <span class="titleSection font-bold text-xl mt-2 mb-2">Restablecer contraseña</span>
            <div class="information ">
                <img src="../../../../../../../assets/img/register/check-account.png" alt="">
                <span>Su contraseña ha sido actualizada con éxito.</span>
            </div>
            <p-button class="nextButton p-0" label="Iniciar sesión" type="submit" [routerLink]="['/auth/login']"></p-button>
        </div>
    </article>
</section>

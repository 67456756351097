<div class="w-full relative">
    <p class="title pl-3 pt-2">Configuración</p>

    <div class="w-full p-1">
        <p-tabView [(activeIndex)]="activeIndex" class="w-full">
          <p-tabPanel class="w-full">
            <ng-template pTemplate="header">
              <div class="hidden md:block">Editar Perfil</div>
              <div class="block md:hidden">
                <i class="pi pi-user text-base" pTooltip="Editar Perfil" tooltipPosition="right"></i>
              </div>
            </ng-template>
        
          </p-tabPanel>
          <p-tabPanel class="w-full">
            <ng-template pTemplate="header">
              <div class="hidden md:block">Cambiar contraseña</div>
              <div class="block md:hidden">
                <i class="pi pi-users text-base" pTooltip="Cambiar contraseña" tooltipPosition="right"></i>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <app-reset-password></app-reset-password>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel class="w-full">
            <ng-template pTemplate="header">
              <div class="hidden md:block">Ayuda</div>
              <div class="block md:hidden">
                <i class="pi pi-file text-base" pTooltip="Ayuda" tooltipPosition="right"></i>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>

</div>

import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Session } from '../../../domain/entities/session.entity';
import { Failure } from 'src/app/core/utils/failure';
import { RegisterUseCase } from '../../../domain/usecases/register.usecase';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserModel } from '../../../data/models/user.model';
import { UserRol } from 'src/app/core/enums/user-rol.enum';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {

  @Input() selectedRol?: string;  
  @Input() selectedTypeResponsibility?: string;  

  registerForm: FormGroup = this.formBuilder.group({
    rol: [, Validators.required],
    name: [, Validators.required],
    last_name: [, Validators.required],
    cuil: [, Validators.required],
    gender: [, Validators.required],
    phone: [, Validators.required],
    birth_date: [, Validators.required],
    civil_status: [, Validators.required],
    province: [, Validators.required],
    city: [, Validators.required],
    street: [, Validators.required],
    number: [, Validators.required],
    floor_apartment: [],
    tower: [],
    postal_code: [, Validators.required],
    company: [, Validators.required],
    company_cuil: [, Validators.required],
    email: [, Validators.required],
    password: [, Validators.required],
    is_employee: [false],
    is_employer: [false],
    is_conciliator: [false],
    comission_position: [ , Validators.required],
    comission_email: [ , Validators.required],
    type_responsibility: [''],
    phone_company: ['']

  });
  isLoading : boolean = false;
  formStep: number = 1;
  stepsTrabajador = [2, 3, 4, 5];
  stepsEmpleadorPropio = [3, 4, 5];
  stepsEmpleadorRepresentante = [3, 4, 5, 6];
  stepsRepresentanteGremial = [3, 4, 5, 6];

  constructor(private formBuilder: FormBuilder,
    private registerUseCase: RegisterUseCase,
    private router: Router,
    private messageService: MessageService) {}


  reciveData(data: FormGroup) {
    this.registerForm.patchValue(data);
    this.setRol();
    this.selectedRol = this.registerForm.get('rol')?.value;    
    this.selectedTypeResponsibility = this.registerForm.get('type_responsibility')?.value;   
    this.formStep++;    
  }

  async register(data: FormGroup) {
    this.registerForm.patchValue(data);
    let user = new UserModel('', '', '', '', '', '', new Date(), '', '', '', '', 0, '', 0, '', '', '', '', '', '',false, false, false, '', '','');
    Object.assign(user, this.registerForm.value);
    this.isLoading = true; 
    let result: boolean | Failure = await this.registerUseCase.execute(user);
    if (result instanceof Failure) {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: result.message });
      return;
    }
    this.formStep++;
    this.isLoading = false;
  }

  nextStep(){
    this.formStep++;
  }

  setRol() {
    switch (this.registerForm.get('rol')?.value) {
      case 0:
        this.registerForm.get('rol')?.setValue(UserRol.WORKER);
        this.registerForm.get('is_employee')?.setValue(true);
        return;
      case 1:
        this.registerForm.get('rol')?.setValue(UserRol.EMPLOYER);
        this.registerForm.get('is_employer')?.setValue(true);
        return;
      case 2:
        this.registerForm.get('rol')?.setValue(UserRol.UNION_REPRESENTATIVE);
        this.registerForm.get('is_conciliator')?.setValue(true);
        return;
      default:
        return;
    }
  }
  previousStep(event: any) {
    if (this.formStep > 1) {
      this.formStep--;
    }
  }

  getSteps() {
    switch (this.selectedRol) {
    case 'Trabajador':
      return this.stepsTrabajador;
    case 'Empleador':
      return this.registerForm.get('type_responsibility')?.value == 'Actúo en nombre propio'
      ? this.stepsEmpleadorPropio
      : this.stepsEmpleadorRepresentante;
    case 'Representante gremial':
      return this.stepsRepresentanteGremial;
    default:
      return [];
    }
}

  isStepValid() {
    const steps = this.getSteps();
    return steps.includes(this.formStep);
  }
}



import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class CompaniesComponent implements OnInit {

  isNewCompany: boolean = true;
  isEditMode: boolean = false;
  activeIndex: number = 0;

  constructor(
    private messageService: MessageService,
  ) {}
  
  ngOnInit(): void {
  }

  handleShowMessage(event: { severity: string, summary: string, detail: string }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

}

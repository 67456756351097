<div class="flex flex-column w-full h-full gap-3 p-4 grid">
    <div class="nuevoReclamo">
        <p class="font-semibold text-xl title">Audiencias</p>

        <div class="busqueda">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Buscar" class="inputBuscador" />
            </span>
            <p-button class="btn-filter ml-2" icon="pi pi-sliders-h"></p-button>
        </div>

    </div>
    <ng-container *ngIf="!tableOrImg">
        <div class="imagenPag">
            <img id="imagen" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
            <p class="descriptionPage">
                Ud. no ha generado ningún reclamo o no tiene fecha de audiencia fijada
            </p>
        </div>
    </ng-container>

    <ng-container *ngIf="tableOrImg">
        <app-audience-table></app-audience-table>
    </ng-container>
</div>
<div *ngIf="isLoading" class="spinner-container">
    <p-progressSpinner></p-progressSpinner>
</div>
<p-toast [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'1000ms'"
    [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" />
<form [formGroup]="resetPasswordForm" class="mt-2" (ngSubmit)="changePassword()">

    <span class="titleSection mt-2 mb-2">Cambiar contraseña</span>
    <div class="grid col-5">

        <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column mt-3">
            <label for="password" id="label-span" class="labelTitle mb-1">Contraseña actual *</label>
            <span class="p-input-icon-right">
                <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    (click)="togglePasswordVisibility()"></i>
                <input pInputText id="password" type="{{ showPassword ? 'text' : 'password' }}" maxlength="32"
                    placeholder="Ingrese su contraseña actual" autocomplete="off" formControlName="password" />
            </span>
        </div>

        <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column mt-3">
            <label for="username" id="laber-span" class="labelTitle mb-1">Nueva contraseña *</label>
            <span class="p-input-icon-right">
                <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    (click)="togglePasswordVisibility()"></i>
                <input pInputText id="newPassword" type="{{ showPassword ? 'text' : 'password' }}" maxlength="32"
                    placeholder="Ingrese la nueva contraseña" autocomplete="off" formControlName="newPassword" />
            </span>
            <div class="text-danger p-error" *ngIf="hasFormError('sameAsOldPassword')">
                La nueva contraseña no puede ser igual a la contraseña actual.
            </div>
        </div>

        <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column mt-3">
            <label for="repeatedPassword" id="laber-span" class="labelTitle mb-1">Repetir nueva contraseña *</label>
            <span class="p-input-icon-right">
                <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    (click)="togglePasswordVisibility()"></i>
                <input pInputText id="repeatedPassword" type="{{ showPassword ? 'text' : 'password' }}" maxlength="32"
                    placeholder="Repita la nueva contraseña" autocomplete="off" formControlName="repeatedPassword" 
                    [ngClass]="{ 'input-error': hasFormError('passwordMismatch') }"/>
            </span>
            <div class="text-danger p-error" *ngIf="hasFormError('passwordMismatch')">
                Las contraseñas no coinciden.
            </div>
        </div>

        <div class="col-12 flex flex-column">
            <span class="mt-2 passValidators" id="uppercase"><i class="pi pi-check-circle"></i> Al menos un carácter en
                mayúscula</span>
            <span class="mt-2 passValidators" id="lowercase"><i class="pi pi-check-circle"></i> Al menos un carácter en
                minúscula</span>
            <span class="mt-2 passValidators" id="minlength"><i class="pi pi-check-circle"></i> Mínimo 8
                caracteres</span>
            <span class="mt-2 passValidators" id="number"><i class="pi pi-check-circle"></i> Mínimo un número</span>
            <span class="mt-2 passValidators" id="specialChar"><i class="pi pi-check-circle"></i> Mínimo un carácter
                especial</span>
        </div>

        <div class="col-12 flex flex-column">
            <p-button class="nextButton" label="Cambiar contraseña" type="submit"
                [disabled]="resetPasswordForm.invalid"></p-button>
            <span class="backLink"><a [routerLink]="['/main/home']">Volver</a></span>
        </div>
    </div>



</form>
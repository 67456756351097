import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Failure } from 'src/app/core/utils/failure';
import { ForgotPasswordSendEmailModel } from 'src/app/features/auth/data/models/forgot-password-send-mail.model';
import { ForgotPasswordSendEmailUseCase } from 'src/app/features/auth/domain/usecases/forgot-password-send-email.useCase';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-send-validation-email',
  templateUrl: './send-validation-email.component.html',
  styleUrls: ['./send-validation-email.component.css']
})
export class SendValidationEmailComponent {
  isLoading : boolean = false;
  emailSend : boolean = false;

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const valid = emailRegex.test(control.value);
      return valid ? null : { 'invalidEmail': true };
    };
  }

  constructor(private formBuilder: FormBuilder,
    private forgotPasswordSendEmailUseCase: ForgotPasswordSendEmailUseCase,
    private messageService: MessageService) { }

  emailForgotPassword: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, this.emailValidator()]],
  })

  async forgotPasswordSendEmail() {
    let params = new ForgotPasswordSendEmailModel(this.emailForgotPassword.get('email')?.value);
    let result = await this.forgotPasswordSendEmailUseCase.execute(params);
    this.isLoading = true;
    if(result instanceof Failure) {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: result.message });
      return;
    }
    this.isLoading = false;
    this.emailSend = true;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.emailForgotPassword.get(controlName)?.hasError(errorType) &&
      this.emailForgotPassword.get(controlName)?.touched
    );
  }

}

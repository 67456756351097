import { Component, OnInit } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { CompanyEntity } from '../../../domain/entities/company.entity';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Toast } from 'primeng/toast';
import { CompanyPaginationParamsModel } from '../../../data/models/company-pagination-params.model';
import { Failure } from 'src/app/core/utils/failure';
import { CompanyPagination } from '../../../domain/entities/company-pagination';
import { GetAllPaginationCompaniesUseCase } from '../../../domain/usecases/get-all-pagination-companies.usecase';
import { DeleteCompanyModel } from '../../../data/models/delete-company.model';
import { DeleteCompanyUseCase } from '../../../domain/usecases/delete-company.usecase';

@Component({
  selector: 'app-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class CompanyTableComponent implements OnInit{
  selectedCompany?: CompanyEntity;
  selectedCompanyId?: string;
  defaultRows: number = 5;
  defaultPage: number = 0;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;
  isLoading: boolean = true;
  homeView: boolean = false;
  paginatedData: CompanyPagination | undefined = undefined;
  companyList: CompanyEntity[] = [];
  public totalQuantity: number = 0;
 
  public items: MenuItem[] = [
    {
      label: 'Editar empresa',
      icon: 'pi pi-pencil',
      command: () => this.editCompany(),
    },
    {
      label: 'Eliminar empresa',
      icon: 'pi pi-trash',
      command: () => this.showOptionPopup(),
    },
  ];

  ngOnInit(): void {
    this.getDataPaginated(this.defaultPage, this.defaultRows);
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }
  }

  constructor(
    private router: Router,
     private messageService: MessageService,
     private confirmationService: ConfirmationService,
     private getAllPaginationCompaniesUseCase: GetAllPaginationCompaniesUseCase,
     private deleteCompanyUseCase: DeleteCompanyUseCase,
    ) { }

  loadRecords(event: TableLazyLoadEvent) {
    const page = event.first !== undefined ? event.first / (event.rows || this.defaultRows) : this.defaultPage;
    const quantity = event.rows || this.defaultRows;
    this.getDataPaginated(page, quantity);
  }

  async getDataPaginated(page: number, quantity: number): Promise<void> {
    let params = new CompanyPaginationParamsModel(page, quantity);
      let result = await this.getAllPaginationCompaniesUseCase.execute(params);
      if (result instanceof Failure) {
        return;
      }
      this.paginatedData = result;
      this.companyList = result.response || [];
      this.totalQuantity = result.totalQuantity;
  }

  editCompany(): void {
    if (this.selectedCompanyId) {
      this.router.navigate(['main/company/update', this.selectedCompanyId]);
    }
  }

  async deleteCompany(): Promise<void> {
    this.isLoading = true;
    let company = new DeleteCompanyModel(this.selectedCompanyId!);
    let result = await this.deleteCompanyUseCase.execute(company);
    this.isLoading = false;
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Empresa eliminada',
      detail: 'La empresa se eliminó exitosamente.',
    });
    setTimeout(() => {
      if(!this.homeView) {
        this.router.navigate(['/main/home']);
      }
    }, 1100);
    this.getDataPaginated(this.defaultPage, this.defaultRows);
   
  }

  showOptionPopup() {
    this.confirmationService.confirm({
      header: 'Eliminar empresa',
      message:
        `¿Está seguro de que desea eliminar la empresa? Se perderán todos los datos.`,
      accept: () => {
        this.deleteCompany();
      },
    });
  }

  selectcompany(id: string) {
    this.selectedCompanyId = id;
  }

  changePage(event: any) {
    this.first = event.first; this.defaultPage = event.page + 1;
    this.amountRegistersPage = event.rows;
    this.itemsPerPage = event.rows
    this.getDataPaginated(this.defaultPage, this.itemsPerPage);
  }
}

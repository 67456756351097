<div class="flex flex-column w-full h-full gap-3 p-4">
  <div class="nuevoReclamo">
      <p class="font-semibold text-xl title">{{titleText}}</p>
      <div class="busqueda">
          <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" pInputText placeholder="Buscar" class="inputBuscador" />
          </span>
          <p-button class="btn-filter ml-2" icon="pi pi-sliders-h"></p-button>
  </div>
  <div *ngIf="!isCommisionRol" class="col-6 text-right">
    <p-button class="btn-new-claim" label="Nuevo reclamo laboral" icon="pi pi-plus"
        routerLink="/main/newLaboralClaim"></p-button>
</div>
  <ng-container >
    <app-claims-table  *ngIf="!isCommisionRol"></app-claims-table>
    <app-claims-comision-table  *ngIf="isCommisionRol"></app-claims-comision-table>
  </ng-container>
</div>

<p-toast></p-toast>

<form [formGroup]="formLogin" class="field flex flex-column w-10 gap-1 justify-content-center" (ngSubmit)="onLogin()">
  <h2 class="col-12 align-content-center">Iniciar sesión</h2>

  <div class="col-12 gap-1 h-5rem lg:col-10">
    <label class="font-semibold text-sm" for="email">Correo electrónico</label>
    <input class="h-2rem w-full" pInputText type="email" maxlength="40" autocomplete="off"
      placeholder="ejemplo@gmail.com" formControlName="email" [ngClass]="{
        'ng-invalid ng-dirty':
          hasErrors('email', 'required')
      }" />
    <div class="text-danger p-error text-sm" *ngIf="hasErrors('email', 'required')">
      Este campo es requerido
    </div>
  </div>
  <div class="col-12 gap-1 h-5rem lg:col-10  ">
    <label class="font-semibold text-sm" for="password">Contraseña</label>
    <span class="p-input-icon-right flex flex-column">
      <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
        (click)="togglePasswordVisibility()"></i>
      <input class="h-2rem w-full" pInputText type="{{ showPassword ? 'text' : 'password' }}" maxlength="32"
        placeholder="Escribir Contraseña" autocomplete="off" formControlName="password" (keydown.enter)="onLogin()"
        [ngClass]="{
          'ng-invalid ng-dirty': hasErrors('password', 'required')
        }" />
    </span>
    <div class="p-error text-sm" *ngIf="hasErrors('password', 'required')">
      Este campo es requerido
    </div>
  </div>

  <div class="grid col-12 lg:col-10 pb-0">
    <div class="col-6 flex flex-row align-items-center gap-1 pb-0" >
      <input [disabled]="!formLogin.valid" type="checkbox" id="checkboxRememberMe" (change)="savePass($event)"
        [checked]="inputCheckbox" class="pb-0"/>
      <label class="font-semibold text-sm pb-0 text-500">Recordarme</label>
    </div>
    <div class="col-6 flex align-items-end justify-content-end align-content-end pb-0">
      <a class="no-underline text-sm font-semibold pb-0" id="text"
        routerLink="/auth/forgot-password/send-validation-email">¿Olvidaste tu contraseña?</a>
    </div>
  </div>

  <div class="col-12  lg:col-10">
    <p-button class="w-full p-button-sm" label="Ingresar" id="btn-submit" (keydown.enter)="onLogin()"
      (onClick)="onLogin()" [disabled]="formLogin.invalid"></p-button>
  </div>
  <div
    class="col-12 flex justify-content-center align-items-center gap-2 lg:col-10 flex justify-content-center gap-2 align-items-center ">
    <span class="text-sm" id="text">¿Aún no tienes una cuenta? </span>
    <span class="registrate"><a class="no-underline text-sm font-bold" id="text"
        routerLink="/auth/register">Registrate.</a></span>
  </div>
</form>

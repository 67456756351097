<form [formGroup]="audienceForm" (ngSubmit)="createAudience()" class="w-full h-full">
    <div class="grid w-full">
        <label class="col-12 text-sm font-semibold">Paso 1 </label>
        <p class="col-12 text-xs font-medium m-0 pt-0">Programar una Meet en Google Calendar</p>
        <div class="col-7">
            <button type="button" pButton label="Agendar en Google Calendar" icon="pi pi-calendar" class="btn-meet"
                style="background-color: #E9ECF2; color: #263245; border-color: #263245;"></button>
        </div>
        <label class="col-12 text-sm font-semibold">Paso 2 </label>
        <p class="col-12 text-xs font-medium m-0 pt-0">Luego cargue los datos para notificar de la audiencia a las
            partes:</p>

        <div class="grid w-full	ml-1 mt-3">
            <div class=" col-12 flex flex-column">
                <label class="labelTitle">Título de la audiencia*</label>
                <input pInputText maxlength="50" formControlName="audienceTitle"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('audienceTitle', 'required') }" />
                <div class="text-danger p-error" *ngIf="hasErrors('audienceTitle', 'required')">
                    Este campo es obligatorio
                </div>
            </div>

            <div class="col-12 flex gap-1">
                <div class="col-6 flex flex-column p-0">
                    <label class="labelTitle">Fecha*</label>
                    <p-calendar class="" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
                        placeholder="DD/MM/AAAA" dateFormat="dd/mm/yy" formControlName="date" [minDate]="minDate"
                        [ngClass]="{'ng-invalid ng-dirty': hasErrors('date', 'required')}" appendTo="body"></p-calendar>
                    <div class="text-danger p-error" *ngIf="hasErrors('date', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
                <div class="col-6 flex flex-column p-0">
                    <label class="labelTitle">Hora*</label>
                    <p-calendar inputId="calendar-timeonly" [timeOnly]="true" formControlName="time"
                        [ngClass]="{'ng-invalid ng-dirty': hasErrors('time', 'required')}" />
                    <div class="text-danger p-error" *ngIf="hasErrors('time', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
            </div>

            <div class="col-12 flex gap-1">
                <div class="col-6 p-0">
                    <label class="labelTitle">Responsable de la audiencia*</label>
                    <input type="text" maxlength="100" pInputText formControlName="conciliatorFullName" />
                </div>
                <div class="col-6 p-0">
                    <label class="labelTitle">Link de Google Meet*</label>
                    <input type="text" maxlength="100" pInputText formControlName="linkGoogleMeet" />
                </div>
            </div>
        </div>

        <div class="grid w-full flex justify-content-end ml-1 mt-3">
            <div class="col-3">
                <button type="button" pButton label="Cancelar" class="btn-cancelar" (click)="cancel()"
                    style="background-color: whitesmoke; color: #263245; border: #263245;"></button>
            </div>
            <div class="col-3">
                <button type="submit" pButton label="Notificar" class="btn-siguiente" [disabled]="audienceForm.invalid"></button>
            </div>
        </div>
    </div>
</form>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginBaseComponent } from './presentation/pages/login-base/login-base.component';
import { RegisterPageComponent } from './presentation/pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './presentation/pages/forgot-password-page/forgot-password-page.component';
import { SendValidationEmailComponent } from './presentation/components/forgot-password/send-validation-email/send-validation-email.component';
import { GenerateNewPasswordComponent } from './presentation/components/forgot-password/generate-new-password/generate-new-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'login', component: LoginBaseComponent, pathMatch: 'full' },
      { path: 'register', component: RegisterPageComponent},
      { path: 'forgot-password', component: ForgotPasswordPageComponent,
        children: [
          { path: 'send-validation-email', component: SendValidationEmailComponent},
          { path: 'generate-new-password', component: GenerateNewPasswordComponent}
        ]
    },
      { path: '**', redirectTo: 'login', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports:[
    RouterModule
  ]
})
export class AuthRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimEntity } from '../../../domain/entities/claim.entity';
import { ClaimModel } from '../../../data/models/claim.model';
import { ClaimUseCase } from '../../../domain/usecases/get-claim.usecase';
import { Failure, UnhandledFailure } from 'src/app/core/utils/failure';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UpdateClaimStatusModel } from '../../../data/models/update-claim-status.model';
import { UpdateClaimStatusEntity } from '../../../domain/entities/update-claim-satus.entity';
import { UpdateClaimStatusUseCase } from '../../../domain/usecases/update-claim-status.usecase';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'app-claim-view',
  templateUrl: './claim-view.component.html',
  styleUrls: ['./claim-view.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class ClaimViewComponent implements OnInit {
  claimId?: string | null;
  claim?: ClaimEntity;
  provinces: ProvinceEntity[] = [];
  claimetProvince?: ProvinceEntity;
  claimedProvince?: ProvinceEntity;
  rol?: string;
  private AES = new AESEncryptDecryptService();
  buttons: boolean = false;
  newStatusClaimUpdate?: UpdateClaimStatusModel;
  labelText: string = 'Aceptar';
  rejectDialogVisible: boolean = false;
  rejectionReason: string = '';
  audienciaDialogVisible: boolean = false;

  constructor(private route: ActivatedRoute,
    private claimUseCase: ClaimUseCase,
    private getProvince: GetProvinceUseCase,
    private localStorageService: LocalStorageServiceBase,
    private confirmationService: ConfirmationService,
    private updateClaimStatusUseCase: UpdateClaimStatusUseCase,
    private messageService: MessageService,
    private router: Router) { }

  ngOnInit(): void {
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.rol = this.AES.decrypt(this.localStorageService.loadStorageData('user_rol'));
    this.getClaim();
    this.loadProvinces();
    this.showButtons();
  }

  async getClaim(): Promise<void> {
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
  }

  getStatusClass(status: string | undefined): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      throw new UnhandledFailure();
    }
    this.setProvinceById();
  }

  setProvinceById(): void {
    this.claimetProvince = this.provinces.find(prov => prov.id === this.claim?.claimetProvince);
    this.claimedProvince = this.provinces.find(prov => prov.id === this.claim?.claimedProvince);
  }

  showButtons() {
    if (this.rol == UserRol.ADMINISTRATOR || this.rol == UserRol.CONCILIATOR) {
      this.buttons = true;
    }
  }

  recibeAceptDialog(action: string): void {
    if (action == 'Admitir') {
      this.updateAdmittedStatusCheck();
    } else {
      this.updateRefucedStatusCheck();
    }
  }

  updateAdmittedStatusCheck() {
    this.labelText = 'Admitir reclamo';
    this.confirmationService.confirm({
      header: 'Admitir reclamo',
      message:
        `¿Desea admitir el reclamo?`,
      accept: () => {
        this.newStatusClaimUpdate = new UpdateClaimStatusModel(this.claimId!, ClaimStatus.ADMITTED);
        this.updateClaimStatus(this.newStatusClaimUpdate);
      }
    });
  }

  updateRefucedStatusCheck() {
    this.labelText = 'Rechazar reclamo';
    this.confirmationService.confirm({
      header: 'Rechazar reclamo',
      message:
        `¿Desea rechazar el reclamo?`,
      accept: () => {
        this.rejectDialogVisible = true;
      },
      reject: () => {
        this.rejectionReason = '';
      }
    });
  }

  confirmRejection() {
    if (this.rejectionReason.trim()) {      
      this.newStatusClaimUpdate = new UpdateClaimStatusModel(this.claimId!, ClaimStatus.REFUSED, this.rejectionReason);
      this.updateClaimStatus(this.newStatusClaimUpdate);
      this.rejectDialogVisible = false;
      this.rejectionReason = '';
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Debe especificar el motivo de rechazo.',
        life: 1200,
      });
    }
    this.rejectionReason = '';
  }

  async updateClaimStatus(claim: UpdateClaimStatusEntity): Promise<void> {
    let result = await this.updateClaimStatusUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Estado no actualizado.',
        life: 900,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Estado actualizado',
      detail: 'El estado se actualizó exitosamente.',
    });
    this.getClaim();
  }

  newAudienceCheck(){
    this.claim
    this.audienciaDialogVisible = true;
  }

  cancel(close: any){
    this.audienciaDialogVisible = false;
  }

  handleShowMessage(event: { severity: string, summary: string, detail: string }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }
}

<form [formGroup]="registerFormRol" (ngSubmit)="nextStep()" class="w-8 sm:w-8 md:w-8 lg:w-7" id="container-form">
  <h2 class=" mb-4 ">Crear cuenta</h2>

  <p class="mb-0">Seleccione un rol</p>

  <div class="grid">
    <div class="col-12 md:col-12 lg:col-12 flex flex-column ">
      <p-selectButton id="rolButton" [options]="rolOptions" formControlName="rol" [multiple]="false" optionLabel="name"
        optionValue="rol"></p-selectButton>
    </div>
  </div>



  <p-button class="nextButton" label="Siguiente" [disabled]="registerFormRol.invalid" type="submit"></p-button>

  <span class="flex justify-content-center" >
    ¿Ya tienes una cuenta? <a (click)="goToLogin()" class="ml-2">Ingresar</a></span>
</form>
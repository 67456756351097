<div class="grid h-full w-full">

    <div class="col-6">
        <div class="flex flex-column justify-content-between align-items-center" id="container-izquierdo">
            <div class="col-6 mt-8">
                <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1494.99 1109.22">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #fff;
                                stroke-width: 0px;
                            }
                        </style>
                    </defs>
                    <g id="Capa_1-2" data-name="Capa 1">
                        <g>
                            <g>
                                <path class="cls-1"
                                    d="M802.1,461.1c41.9-32.4,81.9-81,103.5-126.1,4.57-9.44,7.53-15.41,7.53-15.41,0,0-5.32-11.47-11.43-22.15-4.51-7.89-9.93-15.33-11.5-17.54-2.2-3-3.37-4.61-3.37-4.61,0,0-15.86,22-19.38,26.44-27.78,34.99-65.76,69.09-103.75,92.87-6.3,3.9-12.2,7.7-13,8.4-5.9,4.8-47.5,25.8-61.2,30.9-3.25,1.19-8.33,3.65-8.33,3.65,0,0,1.46,3.69,3.63,8.45,1.8,3.8,3.5,8,3.9,9.4.5,1.4,3.2,8.1,6,15,2.9,6.9,6.6,15.9,8.1,20,1.6,4.1,3.3,8.4,3.8,9.5.5,1.1,1.6,3.6,2.5,5.7.9,2,2.26,5.92,2.26,5.92,0,0,7.98-1.79,21.84-8.22s23.7-12.1,26-13.9c.9-.6,4.2-2.5,13-7.4,4.4-2.4,20.5-13.8,29.9-20.9Z" />
                                <path class="cls-1"
                                    d="M922.7,190.4c-10.8-10.8-31-28.6-39.1-34.4-9.8-7.1-22-15.6-22.4-15.6-.3,0-2.9-1.6-5.9-3.5-9.2-5.8-39.5-21.5-44.6-23-1.4-.4-6.2-2.3-10.8-4.1-4.5-1.9-9-3.3-9-3.3,0,0-9.73,6.01-18.61,11.7-7.02,4.51-13.3,8.68-15.29,10.5-1.24,1.1-3.04,2.7-3.04,2.7,0,0,23.34,15.5,34.24,23.6,11.8,8.7,38.4,32.3,47.5,42,4.7,5.1,10.1,11.3,21.4,24.4,15.4,18.1,45.6,63.7,53.1,80.5,1.2,2.7,5.4,11.5,9.3,19.5s10.67,24.36,10.67,24.36c0,0,13.03-4.46,19.23-6.96,6.2-2.4,13.8-5.6,16.8-6.9,3-1.4,8.9-3.8,13.2-5.5,4.3-1.6,11.1-4.3,15.2-5.9,4-1.7,11.43-4.07,11.43-4.07,0,0-5.29-12.85-6.73-15.87-21.68-45.59-44.43-78.27-76.6-110.16Z" />
                                <path class="cls-1"
                                    d="M566.16,164.66s-31.86,11.84-36.36,13.94c-2.5,1.1-10.6,4.4-18,7.3-16.1,6.4-21.25,9.88-21.25,9.88,0,0,23.75,48.52,33.05,62.42,17.1,25.7,27.1,38.1,46.6,58.1,28.1,28.8,57.2,50.6,90.5,67.9,8,4.1,49.5,21.9,57.4,24.3,3.25.95,8.28,2.3,8.28,2.3,0,0,9.96-4.71,18.94-9.96,7.16-4.19,17.78-12.24,17.78-12.24l-8-3.9c-4.3-2.1-13.1-7.1-19.4-11.1-6.3-4-12.4-7.8-13.5-8.5-3.2-2-23.9-17.2-25.5-18.8-.8-.8-4.9-4.2-9-7.6-9.7-8-33.6-32.2-43.7-44.3-17.1-20.6-36.9-48.9-49.1-70.2-9.4-16.2-28.74-59.54-28.74-59.54Z" />
                                <path class="cls-1"
                                    d="M818,4.1c-.9-2.1-2.2-4.1-2.2-4.1,0,0-1.6,0-19.9,8.6-21.8,10.3-55.2,30.6-69.2,42.2-3,2.5-8.4,6.9-12,9.6-7.4,5.9-24,21.8-35.4,34-22.9,24.8-49.7,65.3-61.5,93.1-1.3,3-2.9,6.7-3.7,8.4-2.7,6.1-6.74,18.36-8.8,23.7-1.22,3.16-2.96,8.56-2.96,8.56,0,0,4.02,7.58,9.32,15.66,4.16,6.34,9.6,13.26,11.14,15.28l3.3,4.1,7.1-12.1c7-11.8,17.9-28.3,24-36.3,22.2-29.1,58.6-63.9,87.5-83.9,4.7-3.2,11.2-7.7,14.5-10,18.5-12.7,50.6-30.2,74.3-40.6,8.3-3.6,12.6-5.88,12.6-5.88,0,0-1.04-3.55-1.96-6.12-2.04-5.7-18.74-46.79-26.14-64.19Z" />
                            </g>
                            <circle class="cls-1" cx="758.99" cy="263.2" r="46.6" />
                            <g>
                                <g>
                                    <path class="cls-1"
                                        d="M5.59,618.68c3.72-6.35,8.78-11.31,15.17-14.88,6.39-3.57,13.39-5.35,21-5.35,8.71,0,16.45,2.14,23.23,6.41,6.78,4.28,11.7,10.33,14.76,18.17h-16.12c-2.12-4.31-5.06-7.53-8.82-9.65s-8.12-3.18-13.06-3.18c-5.41,0-10.23,1.22-14.47,3.65-4.24,2.43-7.55,5.92-9.94,10.47-2.39,4.55-3.59,9.84-3.59,15.88s1.19,11.33,3.59,15.88c2.39,4.55,5.71,8.06,9.94,10.53,4.23,2.47,9.06,3.71,14.47,3.71,4.94,0,9.29-1.06,13.06-3.18s6.71-5.33,8.82-9.65h16.12c-3.06,7.84-7.98,13.88-14.76,18.12-6.78,4.23-14.53,6.35-23.23,6.35-7.69,0-14.7-1.78-21.06-5.35-6.35-3.57-11.39-8.53-15.12-14.88-3.73-6.35-5.59-13.53-5.59-21.53s1.86-15.17,5.59-21.53Z" />
                                    <path class="cls-1"
                                        d="M128.28,676.73c-6.39-3.57-11.45-8.55-15.17-14.94-3.73-6.39-5.59-13.59-5.59-21.59s1.86-15.17,5.59-21.53c3.72-6.35,8.78-11.31,15.17-14.88,6.39-3.57,13.39-5.35,21-5.35s14.72,1.78,21.12,5.35c6.39,3.57,11.43,8.53,15.12,14.88,3.69,6.35,5.53,13.53,5.53,21.53s-1.84,15.2-5.53,21.59c-3.69,6.39-8.72,11.37-15.12,14.94-6.39,3.57-13.43,5.35-21.12,5.35s-14.61-1.78-21-5.35ZM163.75,666.73c4.24-2.47,7.55-6,9.94-10.59,2.39-4.59,3.59-9.9,3.59-15.94s-1.2-11.33-3.59-15.88c-2.39-4.55-5.71-8.04-9.94-10.47-4.23-2.43-9.06-3.65-14.47-3.65s-10.23,1.22-14.47,3.65c-4.24,2.43-7.55,5.92-9.94,10.47-2.39,4.55-3.59,9.84-3.59,15.88s1.19,11.35,3.59,15.94c2.39,4.59,5.71,8.12,9.94,10.59,4.23,2.47,9.06,3.71,14.47,3.71s10.23-1.24,14.47-3.71Z" />
                                    <path class="cls-1"
                                        d="M306.91,599.51v81.76h-13.41v-56l-24.94,56h-9.29l-25.06-56v56h-13.41v-81.76h14.47l28.7,64.11,28.58-64.11h14.35Z" />
                                    <path class="cls-1" d="M354.55,599.51v81.76h-13.41v-81.76h13.41Z" />
                                    <path class="cls-1"
                                        d="M400.9,679.2c-4.39-1.92-7.84-4.65-10.35-8.18-2.51-3.53-3.76-7.65-3.76-12.35h14.35c.31,3.53,1.71,6.43,4.18,8.71,2.47,2.28,5.94,3.41,10.41,3.41s8.23-1.12,10.82-3.35,3.88-5.12,3.88-8.65c0-2.74-.8-4.98-2.41-6.71-1.61-1.72-3.61-3.06-6-4-2.39-.94-5.71-1.96-9.94-3.06-5.33-1.41-9.67-2.84-13-4.29-3.33-1.45-6.18-3.71-8.53-6.76-2.35-3.06-3.53-7.13-3.53-12.23,0-4.71,1.18-8.82,3.53-12.35,2.35-3.53,5.65-6.23,9.88-8.12,4.23-1.88,9.13-2.82,14.7-2.82,7.92,0,14.41,1.98,19.47,5.94,5.06,3.96,7.86,9.39,8.41,16.29h-14.82c-.24-2.98-1.65-5.53-4.23-7.65-2.59-2.12-6-3.18-10.23-3.18-3.84,0-6.98.98-9.41,2.94-2.43,1.96-3.65,4.78-3.65,8.47,0,2.51.76,4.57,2.29,6.18,1.53,1.61,3.47,2.88,5.82,3.82,2.35.94,5.57,1.96,9.65,3.06,5.41,1.49,9.82,2.98,13.23,4.47,3.41,1.49,6.31,3.79,8.71,6.88,2.39,3.1,3.59,7.23,3.59,12.41,0,4.16-1.12,8.08-3.35,11.76-2.24,3.69-5.49,6.65-9.76,8.88-4.28,2.24-9.31,3.35-15.12,3.35-5.49,0-10.43-.96-14.82-2.88Z" />
                                    <path class="cls-1" d="M489.83,599.51v81.76h-13.41v-81.76h13.41Z" />
                                    <path class="cls-1"
                                        d="M540.35,676.73c-6.39-3.57-11.45-8.55-15.17-14.94-3.73-6.39-5.59-13.59-5.59-21.59s1.86-15.17,5.59-21.53c3.72-6.35,8.78-11.31,15.17-14.88,6.39-3.57,13.39-5.35,21-5.35s14.72,1.78,21.12,5.35c6.39,3.57,11.43,8.53,15.12,14.88,3.69,6.35,5.53,13.53,5.53,21.53s-1.84,15.2-5.53,21.59c-3.69,6.39-8.72,11.37-15.12,14.94-6.39,3.57-13.43,5.35-21.12,5.35s-14.61-1.78-21-5.35ZM575.82,666.73c4.24-2.47,7.55-6,9.94-10.59,2.39-4.59,3.59-9.9,3.59-15.94s-1.2-11.33-3.59-15.88c-2.39-4.55-5.71-8.04-9.94-10.47-4.23-2.43-9.06-3.65-14.47-3.65s-10.23,1.22-14.47,3.65c-4.24,2.43-7.55,5.92-9.94,10.47-2.39,4.55-3.59,9.84-3.59,15.88s1.19,11.35,3.59,15.94c2.39,4.59,5.71,8.12,9.94,10.59,4.23,2.47,9.06,3.71,14.47,3.71s10.23-1.24,14.47-3.71Z" />
                                    <path class="cls-1"
                                        d="M700.05,681.26h-13.41l-40.35-61.05v61.05h-13.41v-81.88h13.41l40.35,60.94v-60.94h13.41v81.88Z" />
                                    <path class="cls-1"
                                        d="M835.68,635.62c-1.96,3.69-5.1,6.69-9.41,9-4.31,2.31-9.84,3.47-16.59,3.47h-14.82v33.17h-13.41v-81.76h28.23c6.27,0,11.59,1.08,15.94,3.24,4.35,2.16,7.61,5.08,9.76,8.76,2.16,3.69,3.24,7.8,3.24,12.35,0,4.16-.98,8.08-2.94,11.76ZM821.09,633.68c2.51-2.31,3.76-5.59,3.76-9.82,0-8.94-5.06-13.41-15.17-13.41h-14.82v26.7h14.82c5.1,0,8.9-1.16,11.41-3.47Z" />
                                    <path class="cls-1"
                                        d="M917.32,664.56h-34.23l-5.88,16.7h-14l29.29-81.88h15.53l29.29,81.88h-14.12l-5.88-16.7ZM913.55,653.62l-13.29-38-13.41,38h26.7Z" />
                                    <path class="cls-1"
                                        d="M1009.07,681.26l-18.82-32.7h-10.23v32.7h-13.41v-81.76h28.23c6.27,0,11.59,1.1,15.94,3.29,4.35,2.2,7.61,5.14,9.76,8.82,2.16,3.69,3.24,7.8,3.24,12.35,0,5.33-1.55,10.18-4.65,14.53-3.1,4.35-7.86,7.31-14.29,8.88l20.23,33.88h-16ZM980.01,637.86h14.82c5.02,0,8.8-1.25,11.35-3.76,2.55-2.51,3.82-5.88,3.82-10.12s-1.26-7.55-3.76-9.94c-2.51-2.39-6.31-3.59-11.41-3.59h-14.82v27.41Z" />
                                    <path class="cls-1" d="M1070.95,599.51v81.76h-13.41v-81.76h13.41Z" />
                                    <path class="cls-1"
                                        d="M1158,599.51v10.94h-21.76v70.82h-13.41v-70.82h-21.88v-10.94h57.05Z" />
                                    <path class="cls-1"
                                        d="M1237.4,664.56h-34.23l-5.88,16.7h-14l29.29-81.88h15.53l29.29,81.88h-14.12l-5.88-16.7ZM1233.63,653.62l-13.29-38-13.41,38h26.7Z" />
                                    <path class="cls-1"
                                        d="M1329.15,681.26l-18.82-32.7h-10.23v32.7h-13.41v-81.76h28.23c6.27,0,11.59,1.1,15.94,3.29,4.35,2.2,7.61,5.14,9.76,8.82,2.16,3.69,3.24,7.8,3.24,12.35,0,5.33-1.55,10.18-4.65,14.53-3.1,4.35-7.86,7.31-14.29,8.88l20.23,33.88h-16ZM1300.1,637.86h14.82c5.02,0,8.8-1.25,11.35-3.76,2.55-2.51,3.82-5.88,3.82-10.12s-1.26-7.55-3.76-9.94c-2.51-2.39-6.31-3.59-11.41-3.59h-14.82v27.41Z" />
                                    <path class="cls-1" d="M1391.03,599.51v81.76h-13.41v-81.76h13.41Z" />
                                    <path class="cls-1"
                                        d="M1474.55,664.56h-34.23l-5.88,16.7h-14l29.29-81.88h15.53l29.29,81.88h-14.12l-5.88-16.7ZM1470.79,653.62l-13.29-38-13.41,38h26.7Z" />
                                </g>
                                <g>
                                    <path class="cls-1"
                                        d="M41.13,902.16c5.09,2.59,9.02,6.31,11.8,11.16,2.77,4.85,4.16,10.5,4.16,16.96s-1.39,12.07-4.16,16.83c-2.77,4.75-6.71,8.4-11.8,10.93-5.09,2.53-11.02,3.79-17.79,3.79H2.59v-63.55h20.76c6.77,0,12.7,1.3,17.79,3.89ZM40.45,947.29c3.96-4.02,5.94-9.69,5.94-17.01s-1.98-13.14-5.94-17.28c-3.96-4.15-9.66-6.22-17.1-6.22h-10.33v46.55h10.33c7.44,0,13.14-2.01,17.1-6.04Z" />
                                    <path class="cls-1"
                                        d="M91.38,906.69v18.65h21.95v8.5h-21.95v19.48h24.69v8.5h-35.12v-63.65h35.12v8.5h-24.69Z" />
                                    <path class="cls-1"
                                        d="M212.73,961.83l-14.63-25.42h-7.96v25.42h-10.42v-63.55h21.95c4.88,0,9.01.85,12.39,2.56,3.38,1.71,5.91,3.99,7.59,6.86,1.68,2.87,2.51,6.07,2.51,9.6,0,4.15-1.2,7.91-3.61,11.29-2.41,3.38-6.11,5.68-11.11,6.9l15.73,26.34h-12.44ZM190.14,928.09h11.52c3.9,0,6.84-.97,8.82-2.93,1.98-1.95,2.97-4.57,2.97-7.86s-.98-5.87-2.93-7.73c-1.95-1.86-4.91-2.79-8.87-2.79h-11.52v21.31Z" />
                                    <path class="cls-1"
                                        d="M261.47,906.69v18.65h21.95v8.5h-21.95v19.48h24.69v8.5h-35.12v-63.65h35.12v8.5h-24.69Z" />
                                    <path class="cls-1" d="M322.92,953.42h21.49v8.41h-31.92v-63.55h10.42v55.14Z" />
                                    <path class="cls-1"
                                        d="M404.94,948.85h-26.61l-4.57,12.99h-10.88l22.77-63.65h12.07l22.77,63.65h-10.97l-4.57-12.99ZM402.02,940.34l-10.33-29.54-10.42,29.54h20.76Z" />
                                    <path class="cls-1"
                                        d="M444.77,913.18c2.89-4.94,6.83-8.79,11.8-11.57,4.97-2.77,10.41-4.16,16.32-4.16,6.77,0,12.79,1.66,18.06,4.98,5.27,3.32,9.1,8.03,11.48,14.13h-12.53c-1.65-3.35-3.93-5.85-6.86-7.5-2.93-1.65-6.31-2.47-10.15-2.47-4.21,0-7.96.95-11.25,2.83-3.29,1.89-5.87,4.6-7.73,8.14-1.86,3.54-2.79,7.65-2.79,12.34s.93,8.81,2.79,12.35c1.86,3.54,4.43,6.26,7.73,8.18,3.29,1.92,7.04,2.88,11.25,2.88,3.84,0,7.22-.82,10.15-2.47,2.93-1.65,5.21-4.14,6.86-7.5h12.53c-2.38,6.1-6.2,10.79-11.48,14.08-5.27,3.29-11.29,4.94-18.06,4.94-5.98,0-11.43-1.39-16.37-4.16-4.94-2.77-8.86-6.63-11.75-11.57-2.9-4.94-4.34-10.52-4.34-16.74s1.45-11.8,4.34-16.73Z" />
                                    <path class="cls-1" d="M538.54,898.28v63.55h-10.42v-63.55h10.42Z" />
                                    <path class="cls-1"
                                        d="M578.46,958.31c-4.97-2.77-8.9-6.64-11.8-11.61-2.9-4.97-4.34-10.56-4.34-16.78s1.45-11.8,4.34-16.73c2.89-4.94,6.83-8.79,11.8-11.57,4.97-2.77,10.41-4.16,16.32-4.16s11.45,1.39,16.42,4.16c4.97,2.77,8.88,6.63,11.75,11.57,2.87,4.94,4.3,10.52,4.3,16.73s-1.43,11.81-4.3,16.78c-2.87,4.97-6.78,8.84-11.75,11.61-4.97,2.77-10.44,4.16-16.42,4.16s-11.35-1.39-16.32-4.16ZM606.03,950.54c3.29-1.92,5.87-4.66,7.73-8.23,1.86-3.57,2.79-7.7,2.79-12.39s-.93-8.81-2.79-12.34c-1.86-3.54-4.44-6.25-7.73-8.14-3.29-1.89-7.04-2.83-11.25-2.83s-7.96.95-11.25,2.83c-3.29,1.89-5.87,4.6-7.73,8.14-1.86,3.54-2.79,7.65-2.79,12.34s.93,8.83,2.79,12.39c1.86,3.57,4.43,6.31,7.73,8.23,3.29,1.92,7.04,2.88,11.25,2.88s7.96-.96,11.25-2.88Z" />
                                    <path class="cls-1"
                                        d="M703.24,961.83h-10.42l-31.37-47.46v47.46h-10.42v-63.65h10.42l31.37,47.37v-47.37h10.42v63.65Z" />
                                    <path class="cls-1"
                                        d="M740.91,906.69v18.65h21.95v8.5h-21.95v19.48h24.69v8.5h-35.12v-63.65h35.12v8.5h-24.69Z" />
                                    <path class="cls-1"
                                        d="M801.36,960.23c-3.42-1.49-6.1-3.61-8.05-6.36-1.95-2.74-2.93-5.94-2.93-9.6h11.16c.24,2.74,1.33,5,3.25,6.77,1.92,1.77,4.62,2.65,8.09,2.65s6.4-.87,8.41-2.61,3.02-3.98,3.02-6.72c0-2.13-.63-3.87-1.88-5.21-1.25-1.34-2.8-2.38-4.66-3.11-1.86-.73-4.44-1.52-7.73-2.38-4.15-1.1-7.51-2.21-10.1-3.34-2.59-1.13-4.8-2.88-6.63-5.26-1.83-2.38-2.74-5.55-2.74-9.51,0-3.66.92-6.86,2.74-9.6,1.83-2.74,4.39-4.85,7.68-6.31,3.29-1.46,7.1-2.19,11.43-2.19,6.16,0,11.2,1.54,15.13,4.62,3.93,3.08,6.11,7.3,6.54,12.67h-11.52c-.18-2.32-1.28-4.3-3.29-5.94-2.01-1.65-4.66-2.47-7.96-2.47-2.99,0-5.43.76-7.32,2.29-1.89,1.52-2.83,3.72-2.83,6.58,0,1.95.59,3.55,1.78,4.8,1.19,1.25,2.7,2.24,4.53,2.97s4.33,1.52,7.5,2.38c4.21,1.16,7.64,2.32,10.29,3.48,2.65,1.16,4.91,2.94,6.77,5.35,1.86,2.41,2.79,5.62,2.79,9.65,0,3.23-.87,6.28-2.61,9.14-1.74,2.87-4.27,5.17-7.59,6.9-3.32,1.74-7.24,2.61-11.75,2.61-4.27,0-8.11-.75-11.52-2.24Z" />
                                    <path class="cls-1" d="M908.44,953.42h21.49v8.41h-31.92v-63.55h10.42v55.14Z" />
                                    <path class="cls-1"
                                        d="M990.46,948.85h-26.61l-4.57,12.99h-10.88l22.77-63.65h12.07l22.77,63.65h-10.97l-4.57-12.99ZM987.54,940.34l-10.33-29.54-10.42,29.54h20.76Z" />
                                    <path class="cls-1"
                                        d="M1072.13,934.58c2.38,2.99,3.57,6.37,3.57,10.15,0,3.23-.84,6.14-2.51,8.73-1.68,2.59-4.1,4.63-7.27,6.13-3.17,1.49-6.86,2.24-11.07,2.24h-25.42v-63.55h24.23c4.33,0,8.05.73,11.16,2.19s5.46,3.43,7.04,5.9c1.58,2.47,2.38,5.23,2.38,8.28,0,3.66-.98,6.71-2.93,9.14-1.95,2.44-4.57,4.24-7.86,5.4,3.41.61,6.31,2.41,8.69,5.4ZM1039.85,924.98h12.89c3.41,0,6.08-.78,8-2.33s2.88-3.79,2.88-6.72-.96-5.1-2.88-6.72c-1.92-1.62-4.59-2.42-8-2.42h-12.89v18.2ZM1062.25,950.77c2.01-1.71,3.02-4.08,3.02-7.13s-1.07-5.58-3.2-7.41c-2.13-1.83-4.97-2.74-8.5-2.74h-13.72v19.84h14.08c3.54,0,6.31-.85,8.32-2.56Z" />
                                    <path class="cls-1"
                                        d="M1113.23,958.31c-4.97-2.77-8.9-6.64-11.8-11.61-2.9-4.97-4.34-10.56-4.34-16.78s1.45-11.8,4.34-16.73c2.89-4.94,6.83-8.79,11.8-11.57,4.97-2.77,10.41-4.16,16.32-4.16s11.45,1.39,16.42,4.16c4.97,2.77,8.88,6.63,11.75,11.57,2.87,4.94,4.3,10.52,4.3,16.73s-1.43,11.81-4.3,16.78c-2.87,4.97-6.78,8.84-11.75,11.61-4.97,2.77-10.44,4.16-16.42,4.16s-11.35-1.39-16.32-4.16ZM1140.8,950.54c3.29-1.92,5.87-4.66,7.73-8.23,1.86-3.57,2.79-7.7,2.79-12.39s-.93-8.81-2.79-12.34c-1.86-3.54-4.44-6.25-7.73-8.14-3.29-1.89-7.04-2.83-11.25-2.83s-7.96.95-11.25,2.83c-3.29,1.89-5.87,4.6-7.73,8.14-1.86,3.54-2.79,7.65-2.79,12.34s.93,8.83,2.79,12.39c1.86,3.57,4.43,6.31,7.73,8.23,3.29,1.92,7.04,2.88,11.25,2.88s7.96-.96,11.25-2.88Z" />
                                    <path class="cls-1"
                                        d="M1218.8,961.83l-14.63-25.42h-7.96v25.42h-10.42v-63.55h21.95c4.88,0,9.01.85,12.39,2.56,3.38,1.71,5.91,3.99,7.59,6.86,1.68,2.87,2.51,6.07,2.51,9.6,0,4.15-1.2,7.91-3.61,11.29-2.41,3.38-6.11,5.68-11.11,6.9l15.73,26.34h-12.44ZM1196.22,928.09h11.52c3.9,0,6.84-.97,8.82-2.93,1.98-1.95,2.97-4.57,2.97-7.86s-.98-5.87-2.93-7.73c-1.95-1.86-4.91-2.79-8.87-2.79h-11.52v21.31Z" />
                                    <path class="cls-1"
                                        d="M1295.43,948.85h-26.61l-4.57,12.99h-10.88l22.77-63.65h12.07l22.77,63.65h-10.97l-4.57-12.99ZM1292.51,940.34l-10.33-29.54-10.42,29.54h20.76Z" />
                                    <path class="cls-1" d="M1344.81,953.42h21.49v8.41h-31.92v-63.55h10.42v55.14Z" />
                                    <path class="cls-1"
                                        d="M1398.95,906.69v18.65h21.95v8.5h-21.95v19.48h24.69v8.5h-35.12v-63.65h35.12v8.5h-24.69Z" />
                                    <path class="cls-1"
                                        d="M1459.39,960.23c-3.42-1.49-6.1-3.61-8.05-6.36-1.95-2.74-2.93-5.94-2.93-9.6h11.16c.24,2.74,1.33,5,3.25,6.77,1.92,1.77,4.62,2.65,8.09,2.65s6.4-.87,8.41-2.61,3.02-3.98,3.02-6.72c0-2.13-.63-3.87-1.88-5.21-1.25-1.34-2.8-2.38-4.66-3.11-1.86-.73-4.44-1.52-7.73-2.38-4.15-1.1-7.51-2.21-10.1-3.34-2.59-1.13-4.8-2.88-6.63-5.26-1.83-2.38-2.74-5.55-2.74-9.51,0-3.66.92-6.86,2.74-9.6,1.83-2.74,4.39-4.85,7.68-6.31,3.29-1.46,7.1-2.19,11.43-2.19,6.16,0,11.2,1.54,15.13,4.62,3.93,3.08,6.11,7.3,6.54,12.67h-11.52c-.18-2.32-1.28-4.3-3.29-5.94-2.01-1.65-4.66-2.47-7.96-2.47-2.99,0-5.43.76-7.32,2.29-1.89,1.52-2.83,3.72-2.83,6.58,0,1.95.59,3.55,1.78,4.8,1.19,1.25,2.7,2.24,4.53,2.97s4.33,1.52,7.5,2.38c4.21,1.16,7.64,2.32,10.29,3.48,2.65,1.16,4.91,2.94,6.77,5.35,1.86,2.41,2.79,5.62,2.79,9.65,0,3.23-.87,6.28-2.61,9.14-1.74,2.87-4.27,5.17-7.59,6.9-3.32,1.74-7.24,2.61-11.75,2.61-4.27,0-8.11-.75-11.52-2.24Z" />
                                </g>
                                <g>
                                    <path class="cls-1"
                                        d="M90.24,781.83c-3.23,6.07-8.39,11-15.48,14.8-7.1,3.81-16.19,5.71-27.29,5.71h-24.38v54.57H1.03v-134.5h46.45c10.32,0,19.06,1.78,26.22,5.32,7.16,3.55,12.51,8.35,16.06,14.42,3.55,6.07,5.32,12.84,5.32,20.32,0,6.84-1.62,13.29-4.84,19.35ZM66.24,778.63c4.13-3.8,6.19-9.19,6.19-16.16,0-14.71-8.32-22.06-24.96-22.06h-24.38v43.93h24.38c8.38,0,14.64-1.9,18.77-5.71Z" />
                                    <path class="cls-1"
                                        d="M169.97,740.22v39.48h46.45v18h-46.45v41.22h52.25v18h-74.31v-134.69h74.31v18h-52.25Z" />
                                    <path class="cls-1"
                                        d="M350.91,856.91l-30.96-53.8h-16.84v53.8h-22.06v-134.5h46.45c10.32,0,19.06,1.81,26.22,5.42,7.16,3.61,12.51,8.45,16.06,14.51,3.55,6.07,5.32,12.84,5.32,20.32,0,8.77-2.55,16.74-7.64,23.9-5.1,7.16-12.94,12.03-23.51,14.61l33.29,55.73h-26.32ZM303.11,785.5h24.38c8.25,0,14.48-2.06,18.67-6.19,4.19-4.13,6.29-9.68,6.29-16.64s-2.06-12.42-6.19-16.35c-4.13-3.93-10.39-5.9-18.77-5.9h-24.38v45.09Z" />
                                    <path class="cls-1"
                                        d="M576.74,722.41v134.5h-22.06v-92.12l-41.03,92.12h-15.29l-41.22-92.12v92.12h-22.06v-134.5h23.8l47.22,105.47,47.03-105.47h23.61Z" />
                                    <path class="cls-1"
                                        d="M718.59,829.43h-56.31l-9.68,27.48h-23.03l48.19-134.69h25.54l48.19,134.69h-23.22l-9.68-27.48ZM712.4,811.43l-21.87-62.51-22.06,62.51h43.93Z" />
                                    <path class="cls-1"
                                        d="M914.63,856.91h-22.06l-66.38-100.44v100.44h-22.06v-134.69h22.06l66.38,100.24v-100.24h22.06v134.69Z" />
                                    <path class="cls-1"
                                        d="M997.45,740.22v39.48h46.45v18h-46.45v41.22h52.25v18h-74.31v-134.69h74.31v18h-52.25Z" />
                                    <path class="cls-1"
                                        d="M1219.03,856.91h-22.06l-66.38-100.44v100.44h-22.06v-134.69h22.06l66.38,100.24v-100.24h22.06v134.69Z" />
                                    <path class="cls-1"
                                        d="M1366.68,722.41v18h-35.8v116.5h-22.06v-116.5h-36v-18h93.86Z" />
                                    <path class="cls-1"
                                        d="M1442.74,740.22v39.48h46.45v18h-46.45v41.22h52.25v18h-74.31v-134.69h74.31v18h-52.25Z" />
                                </g>
                            </g>
                            <rect class="cls-1" x=".1" y="1087.6" width="1494.14" height="21.62" />
                        </g>
                    </g>
                </svg>
            </div>


            <div class="col-12 flex flex-column align-items-center justify-content-center">
                <div class="text-white flex flex-column align-items-center justify-content-center font-normal text-xl">
                    <span class="font-bold text-3xl">SICOL</span>
                    <span>Un Sistema de la Comisión Paritaria Permanente</span>
                    <span>de Relaciones Laborales</span>
                </div>
                <img class="img-logo mb-3 mt-6" src="../../../../../../assets/img/login/new-logo-encode-white.png" alt="" />
                <div class="flex justify-content-center hidden">
                  <div class="hidden lg:flex flex-col justify-content-center pb-4 w-4">
                    <p class="version">v.0.0.1</p>
                  </div>
                </div>
              </div>
        </div>

    </div>

    <div class="col-6 flex flex-column align-items-center justify-content-center" id="">
        <router-outlet></router-outlet>
    </div>
</div>

<section class="grid flex flex-column w-full p-5">
    <div class="card flex justify-content-center w-full relative">
        <p-toast />
        <p-confirmDialog #cd>
            <ng-template pTemplate="headless" let-message class="congirmDialog">
                <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                    <span class="labelTitle1 text-lg font-semibold mb-1">
                        {{ message.header }}
                    </span>
                    <div class=" flex justify-content-center align-items-center gap-2">
                        <i class="pi pi-exclamation-circle"></i>
                        <p class="text-base">{{ message.message }}</p>
                    </div>
                    <div class="flex flex-row justify-content-center align-items-center gap-2 mt-3 ">
                        <ng-container class="col-12">
                            <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border: 1px solid #263245;
                                background-color: white;
                                color: #263245;
                                font-size: 0.8rem;">
                            </button>
                            <button pButton [label]="labelText" (click)="cd.accept()" class="btn-siguiente col-6 popup" style="
                                background-color: #263245;
                                border-color: #263245;
                                color: white !important;
                                font-size: 0.8rem;
                                ">
                            </button>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </p-confirmDialog>
        <p-dialog header="Rechazar reclamo" [(visible)]="rejectDialogVisible" [modal]="true" [closable]="false">
            <div class="p-fluid">
                <div class="p-field flex flex-column ">
                    <label class="font-semibold" for="reason">Motivo:</label>
                    <input id="reason" [(ngModel)]="rejectionReason" rows="5"  class="inputtarea">
                </div>
            </div>
            <div class="flex justify-content-end gap-2">
                <button pButton label="Cancelar" (click)="rejectDialogVisible = false" (click)="rejectionReason = ''" class="btn-cancelar" style="border: 1px solid #263245;
                background-color: white;
                color: #263245;
                font-size: 0.8rem;"></button>
                <button pButton label="Rechazar reclamo" (click)="confirmRejection()"></button>
            </div>
        </p-dialog>
    </div>

    <div>
        <p-dialog header="Fijar Audiencia" [(visible)]="audienciaDialogVisible" [modal]="true" [closable]="false" >
            <app-audience (closePopup)="cancel($event)"  [claim]="claim" (showMessage)="handleShowMessage($event)" ></app-audience>
        </p-dialog>
          
    </div>
    
    <div class="col-12 flex flex-row align-items-center gap-2 m-2">
        <i class="pi pi-angle-left"></i>
        <p><a href="" routerLink="/main/claims">Mis reclamos laborales</a></p>
        <p>/</p>
        <p><a class="font-bold">Detalle del reclamo</a></p>
    </div>

    <div class="col-12 flex justify-content-between align-items-center p-2">
        <span class="titleSection font-bold text-xl mt-2 mb-2">Detalle del reclamo</span>
        <p-button icon="pi pi-download" class=" p-0" label="Descargar"></p-button>
    </div>

    <article class="col-12">
        <div class="col-6 shadow-2 p-4 border-round-sm m-0">
            <div class="flex justify-content-between align-items-center">
                <span class="titleSection font-medium text-lg ">Número de reclamo: {{claim?.proceedings}}</span>
                <p [ngClass]="getStatusClass(claim?.status)">{{claim?.status! | claimStatusDisplay}}</p>
            </div>
            <p>Fecha de inicio: {{claim?.created | date: 'dd/MM/yyyy'}} </p>
        </div>
    </article>

    <article class="col-12">
        <div class="shadow-2 p-4 border-round-sm">
            <div>
                <span class="titleSection font-bold text-base mt-2 mb-2">Detalle del reclamo</span>
            </div>
            <div class="grid mt-2">
                <div class="col-4">
                    <p>Objeto del reclamo</p>
                    <p class="bold">{{claim?.selecterObjectOfClaim}}</p>
                </div>
                <div class="col-12">
                    <p>Detalle del reclamo</p>
                    <p class="bold">{{claim?.textClaimDetails}}</p>
                </div>
                <div class="col-12">
                    <p>Documentación extra</p>
                    <p class="bold">{{claim?.extraDocumentationPdf}}</p>
                </div>
            </div>

            <hr class="mb-3">
            <span class="titleSection font-bold text-base mt-2 mb-2">Representación letrada</span>
            <div class="grid mt-2">
                <div class="col-4 ">
                    <p>Apellido y Nombre</p>
                    <p class="bold">{{claim?.lawyerFullName}}</p>
                </div>
                <div class="col-4">
                    <p>Matrícula</p>
                    <p class="bold">{{claim?.lawyerRegistrationNumber}}</p>
                </div>
                <div class="col-4">
                    <p>Correo electrónico</p>
                    <p class="bold">{{claim?.lawyerEmail}}</p>
                </div>
            </div>
        </div>
    </article>
    <article class="col-12">
        <div class="shadow-2 p-4 border-round-sm">
            <span class="titleSection font-bold text-base mt-2 mb-2">Datos de reclamante</span>
            <div class="grid mt-2">
                <div class="col-5">
                    <p>Apellido</p>
                    <p class="bold">{{claim?.claimetLastname}}</p>
                </div>
                <div class="col-4">
                    <p>Nombre</p>
                    <p class="bold">{{claim?.claimetName}}</p>
                </div>
                <div class="col-3">
                    <p>CUIL</p>
                    <p class="bold">{{claim?.claimetCuil}}</p>
                </div>
                <div class="col-5">
                    <p>Correo electrónico</p>
                    <p class="bold">{{claim?.claimetEmail}}</p>
                </div>
                <div class="col-4">
                    <p>Teléfono</p>
                    <p class="bold">{{claim?.claimetPhone}}</p>
                </div>
                <div class="col-12">
                    <p>DNI frente y dorso</p>
                    <p class="bold">{{claim?.claimetFileDni}}</p>
                </div>
                <div class="col-3">
                    <p>Calle</p>
                    <p class="bold">{{claim?.claimetStreet}}</p>
                </div>
                <div class="col-2">
                    <p>Número</p>
                    <p class="bold">{{claim?.claimetNumber}}</p>
                </div>
                <div class="col-2">
                    <p>Piso/Depto</p>
                    <p class="bold">{{claim?.claimetFloorApartament}}</p>
                </div>
                <div class="col-2">
                    <p>Torre</p>
                    <p class="bold">{{claim?.claimetTower}}</p>
                </div>
                <div class="col-3">
                    <p>Código postal</p>
                    <p class="bold">{{claim?.claimetPostalCode}}</p>
                </div>
                <div class="col-3">
                    <p>Barrio</p>
                    <p class="bold">{{claim?.claimetNeighborhood}}</p>
                </div>
                <div class="col-2">
                    <p>Localidad</p>
                    <p class="bold">{{claim?.claimetCity}}</p>
                </div>
                <div class="col-2">
                    <p>Provincia</p>
                    <p class="bold">{{claimetProvince?.name}}</p>
                </div>
            </div>

            <hr class="mt-3 mb-3">
            <div class="grid">
                <div class="col-3">
                    <p>Tipo de trabajo</p>
                    <p class="bold">{{claim?.claimetSelectedTypeOfJob}}</p>
                </div>
                <div class="col-5">
                    <p>Estado actual de la relación laboral</p>
                    <p class="bold">{{claim?.claimetCurrentEmploymentStatus}}</p>
                </div>
                <div class="col-2">
                    <p>Jornada laboral</p>
                    <p class="bold">{{claim?.claimetWorkday}}</p>
                </div>
                <div class="col-3">
                    <p>Remuneración bruta</p>
                    <p class="bold">${{claim?.claimetGrossRemuneration}}</p>
                </div>
                <div class="col-3">
                    <p>Tipo de Remuneración</p>
                    <p class="bold">{{claim?.claimetTypeRemuneration}}</p>
                </div>
                <div class="col-2">
                    <p>Fecha de ingreso</p>
                    <p class="bold">{{claim?.claimetDateAdmission | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="col-2">
                    <p>Fecha de egreso</p>
                    <p class="bold">{{claim?.claimetEgressDate | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="col-3">
                    <p>Categoría de convenio colectivo</p>
                    <p class="bold">{{claim?.claimetCategory}}</p>
                </div>
                <div class="col-3">
                    <p>Número</p>
                    <p class="bold">{{claim?.claimetNumberAgreement}}</p>
                </div>
                <div class="col-2">
                    <p>Año</p>
                    <p class="bold">{{claim?.claimetYear}}</p>
                </div>
                <div class="col-12">
                    <p>Recibo de sueldo</p>
                    <p class="bold">{{claim?.claimetFilePaycheck}}</p>
                </div>
            </div>

           
        </div>
    </article>

    <article class="col-12">
        <div class="shadow-2 p-4 border-round-sm">
            <span class="titleSection font-bold text-base mt-2 mb-2">Datos del reclamado</span>
            <div class="grid mt-2">
                <div class="col-4">
                    <p>Tipo de persona</p>
                    <p class="bold">{{claim?.claimedTypeOfPerson}}</p>
                </div>
                <div class="col-4">
                    <p>Apellido y nombre o Razón social</p>
                    <p class="bold">{{claim?.claimedNameLastNameOrBusinessName}}</p>
                </div>
                <div class="col-4">
                    <p>CUIT/CUIL</p>
                    <p class="bold">{{claim?.claimedCuil}}</p>
                </div>
                <div class="col-4">
                    <p>Correo electrónico</p>
                    <p class="bold">{{claim?.claimedEmail}}</p>
                </div>
                <div class="col-8">
                    <p>Teléfono</p>
                    <p class="bold">{{claim?.claimedPhone}}</p>
                </div>
                <div class="col-2">
                    <p>Calle</p>
                    <p class="bold">{{claim?.claimedStreet}}</p>
                </div>
                <div class="col-2">
                    <p>Número</p>
                    <p class="bold">{{claim?.claimedNumber}}</p>
                </div>
                <div class="col-4">
                    <p>Piso/Depto</p>
                    <p class="bold">{{claim?.claimedFloorApartment}}</p>
                </div>
                <div class="col-2">
                    <p>Torre</p>
                    <p class="bold">{{claim?.claimedTower}}</p>
                </div>
                <div class="col-2">
                    <p>Código postal</p>
                    <p class="bold">{{claim?.claimedPostalCode}}</p>
                </div>
                <div class="col-4">
                    <p>Barrio</p>
                    <p class="bold">{{claim?.claimedNeighborhood}}</p>
                </div>
                <div class="col-4">
                    <p>Provincia</p>
                    <p class="bold">{{claimedProvince?.name}}</p>
                </div>
                <div class="col-4">
                    <p>Localidad</p>
                    <p class="bold">{{claim?.claimedCity}}</p>
                </div>
            </div>
        </div>
    </article>

    <article class="col-12">
        <div class="shadow-2 p-4 border-round-sm">
            <div>
                <span class="titleSection font-bold text-base mt-2 mb-2">Hostorial de audiencias</span>
            </div>
            <p-table [tableStyle]="{ 'min-width': '100%'}" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}" class="col-12">
                <ng-template pTemplate="header">
                    <tr class="font-bold">

                        <th class="col-12 sm:col-6 md:col-3">Fecha</th>

                        <th class="col-12 sm:col-6 md:col-1"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-claim>
                    <tr class="table-row">

                        <td class="col-12 sm:col-6 md:col-3">{{ claim.startDate | date: 'dd/MM/yyyy' }}
                        </td>

                        <td class="col-12 sm:col-6 md:col-1 text-right">
                            <p-menu #menu [popup]="true" appendTo="body"></p-menu>
                            <button type="button" pButton icon="pi pi-ellipsis-v"
                                class="p-button-rounded p-button-text"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </article>

    <div *ngIf="buttons">

        <div *ngIf="(claim?.status === 'Pendiente') && (rol === 'Administrador')"
            class="grid w-full flex justify-content-end mt-3">
            <div class="col-6 md:col-3 lg:col-3 xl:col-3 ">
                <p-button (click)="recibeAceptDialog('Rechazar')"  label="Rechazar reclamo" class="btn-cancelar sm:text-xs" />
            </div>

            <div class="col-6 md:col-3 lg:col-3 xl:col-3 ">
                <p-button (click)="recibeAceptDialog('Admitir')"  label="Admitir reclamo" class=" sm:text-xs" />
            </div>
        </div>

        <div *ngIf="(claim?.status === 'AudienciaFijada')" class="grid w-full flex justify-content-end mt-3">
            <div class="col-6 md:col-3 lg:col-3 xl:col-3 ">
                <p-button (click)="newAudienceCheck()"  label="Fijar audiencia" class="btn-cancelar sm:text-xs" />
            </div>

            <div class="col-6 md:col-3 lg:col-3 xl:col-3 ">
                <p-button  label="Crear acta" class=" sm:text-xs" />
            </div>
        </div>

        <div  *ngIf="(claim?.status === 'Admitido')" class="grid w-full flex justify-content-end mt-3">
            <div class="col-6 md:col-3 lg:col-3 xl:col-3 ">
                <p-button (click)="newAudienceCheck()"  label="Fijar audiencia" class=" sm:text-xs" />
            </div>
        </div>
    </div>
</section>
<div class="card flex justify-content-center w-full relative">
  <p-toast />
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message class="congirmDialog">
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        <span class="labelTitle1">
          {{ message.header }}
        </span>
        <div class=" flex justify-content-center align-items-center gap-2">
          <i class="pi pi-exclamation-circle"></i>
          <p class="text-base">{{ message.message }}</p>
        </div>
        <div class="flex flex-row justify-content-center align-items-center gap-2 mt-3 ">
          <ng-container *ngIf="isNewUser" class="col-12">
            <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border-color: #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
            </button>
            <button pButton label="Guardar como borrador" (click)="cd.accept()" class="btn-siguiente col-6" style="
                            background-color: #263245;
                            border-color: #263245;
                            font-size: 0.8rem;
                            ">
            </button>
          </ng-container>
          <ng-container *ngIf="!isNewUser" class="col-12">
            <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border-color: #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
            </button>
            <button pButton label="Aceptar" (click)="cd.accept()" class="btn-siguiente col-6" style="
                            background-color: #263245;
                            border-color: #263245;
                            font-size: 0.8rem;
                            ">
            </button>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>

<div class="w-full relative p-4">
  <div class="container-top">
    <i class="pi pi-angle-left"></i>
    <p><a href="" routerLink="/main/users">Usuario</a></p>
    <p>/</p>
    <p><a class="font-bold">Nuevo Usuario</a></p>
  </div>
  <div class="container-title">
    <p *ngIf="isNewUser" class="title">Nuevo Usuario</p>
    <p *ngIf="!isNewUser" class="title">Editar Usuario</p>
    <p class="description">
      Complete los datos requeridos para añadir un nuevo usuario
    </p>
  </div>

  <div class="w-full">
    <form [formGroup]="usersForm" class="w-full" (ngSubmit)="onSubmit($event)">
      <div class="grid">
        <!-- Apellido, Nombre, Correo electrónico -->
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="lastname">Apellido *</label>
          <input id="lastname" placeholder="Apellido" type="text" pInputText
            formControlName="lastname"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('lastname', 'required') || hasErrors('lastname', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('lastname', 'required')">
            Este campo es obligatorio
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('lastname', 'maxlength')">
            Este campo no puede poseer más de 50 caracteres
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('lastname', 'pattern')">
            El campo solo acepta letras.
          </div>
        </div>

        <div class="col-12 md:col-4">
          <label class="labelTitle" for="name">Nombre *</label>
          <input id="name" placeholder="Nombre" type="text" pInputText
            formControlName="name"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('name', 'required') || hasErrors('name', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('name', 'required')">
            Este campo es obligatorio
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('name', 'maxlength')">
            Este campo no puede poseer más de 50 caracteres
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('name', 'pattern')">
            El campo solo acepta letras.
          </div>
        </div>

        <div class="col-12 md:col-4">
          <label class="labelTitle" for="email">Correo electrónico*</label>
          <input id="email" placeholder="Correo electrónico" type="email" pInputText
            formControlName="email"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('email', 'required') || hasErrors('email', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('email', 'required')">
            Este campo es obligatorio
          </div>
        </div> 

        <!-- CUIL, DNI, Teléfono, Rol -->
        <div class="col-12 md:col-3">
          <label class="labelTitle" for="cuil">CUIL*</label>
          <p-inputMask mask="99-99999999-9" placeholder="99-99999999-9" formControlName="cuil" [ngClass]="{
                  'ng-invalid ng-dirty': hasErrors('cuil', 'required')
                }"></p-inputMask>
          <div class="text-danger p-error" *ngIf="hasErrors('cuil', 'required')">
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-3">
          <label class="labelTitle" for="dni">DNI*</label>
          <input id="dni" placeholder="DNI" type="text" pInputText formControlName="dni" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('dni', 'required') || hasErrors('dni', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('dni', 'required')">
            Este campo es obligatorio
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('dni', 'pattern')">
            El campo solo acepta números.
          </div>
        </div>
       
        <div class="col-12 md:col-3">
          <label class="labelTitle" for="phone">Teléfono*</label>
          <input id="phone" placeholder="Teléfono" type="tel" pInputText formControlName="phone" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('phone', 'required') || hasErrors('phone', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('phone', 'required')">
            Este campo es obligatorio
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('phone', 'minlength')">
            Este campo no puede poseer menos de 9 caracteres
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('phone', 'maxlength')">
            Este campo no puede poseer más de 15 caracteres
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('phone', 'pattern')">
            El campo solo acepta números.
          </div>
        </div>
        <div class="col-12 md:col-3">
          <label class="labelTitle" for="rol">Rol*</label>
          <p-dropdown id="rol" [options]="roles" formControlName="rol" placeholder="Seleccione Rol"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('rol', 'required') || hasErrors('rol', 'maxlength')}"></p-dropdown>
          <div class="text-danger p-error" *ngIf="hasErrors('rol', 'required')">
            Este campo es obligatorio
          </div>
        </div>

        <!-- Calle, Número, Piso/Depto, Torre, Código postal -->
        <div class="col-12 md:col-3">
          <label class="labelTitle" for="street">Calle*</label>
          <input id="street" placeholder="Calle" type="text" pInputText formControlName="street" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('street', 'required') || hasErrors('street', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('street', 'required')">
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-2">
          <label class="labelTitle" for="number">Número*</label>
          <input id="number" placeholder="Número" type="text" pInputText formControlName="number" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('number', 'required') || hasErrors('number', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('number', 'required')">
            Este campo es obligatorio
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('number', 'pattern')">
            El campo solo acepta números.
          </div>
        </div>
        <div class="col-12 md:col-2">
          <label class="labelTitle" for="floorApartment">Piso/Depto</label>
          <input id="floorApartment" placeholder="Piso/Depto" type="text" pInputText formControlName="floorApartment" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('floorApartment', 'required') || hasErrors('floorApartment', 'maxlength')}">
        </div>
        <div class="col-12 md:col-2">
          <label class="labelTitle" for="tower">Torre</label>
          <input id="tower" placeholder="Torre" type="text" pInputText formControlName="tower" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('tower', 'required') || hasErrors('tower', 'maxlength')}">
        </div>
        <div class="col-12 md:col-3">
          <label class="labelTitle" for="postalCode">Código postal*</label>
          <input id="postalCode" placeholder="Código postal" type="text" pInputText formControlName="postalCode"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('postalCode', 'required') || hasErrors('postalCode', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('postalCode', 'required')">
            Este campo es obligatorio
          </div>
          <div class="text-danger p-error" *ngIf="hasErrors('postalCode', 'pattern')">
            El campo solo acepta números.
          </div>
        </div>

        <!-- Barrio, Provincia, Localidad -->
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="neighborhood">Barrio*</label>
          <input id="neighborhood" placeholder="Barrio" type="text" pInputText formControlName="neighborhood" [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('neighborhood', 'required') || hasErrors('neighborhood', 'maxlength')}">
          <div class="text-danger p-error" *ngIf="hasErrors('neighborhood', 'required')">
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="province">Provincia*</label>
          <p-dropdown id="province" [options]="provinces" optionLabel="name" optionValue="id" placeholder="Provincia"
            formControlName="province" placeholder="Seleccione la provincia"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('province', 'required') || hasErrors('province', 'maxlength')}"></p-dropdown>
          <div class="text-danger p-error" *ngIf="hasErrors('province', 'required')">
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="city">Localidad*</label>
          <p-dropdown [options]="localities" optionLabel="name" optionValue="name" [showClear]="false" id="city"
            placeholder="Localidad" formControlName="city" placeholder="Seleccione la localidad"
            [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('city', 'required') || hasErrors('city', 'maxlength')}"></p-dropdown>
          <div class="text-danger p-error" *ngIf="hasErrors('city', 'required')">
            Este campo es obligatorio
          </div>
        </div>
        <div class="grid w-full flex justify-content-end mt-2">
          <div class="col-6 md:col-2 lg:col-2 xl:col-2 ">
            <p-button (click)="cancel()" label="Cancelar" class="btn-cancelar sm:text-xs" />
          </div>

          <div class="col-6 md:col-2 lg:col-2 xl:col-2 pr-0">
            <p-button label="Guardar" class="btn-siguiente" [disabled]="usersForm.invalid" type="submit"></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
<div class="card flex justify-content-center w-full relative">
    <p-toast />
    <p-confirmDialog #cd>
        <ng-template pTemplate="headless" let-message class="congirmDialog">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <span class="labelTitle1">
                    {{ message.header }}
                </span>
                <div class=" flex justify-content-center align-items-center gap-2">
                    <i class="pi pi-exclamation-circle"></i>
                    <p class="text-base">{{ message.message }}</p>
                </div>
                <div class="flex flex-row justify-content-center align-items-center gap-2 mt-3 ">
                    <ng-container class="col-12">
                        <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border-color: #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
                        </button>
                        <button pButton label="Aceptar" (click)="cd.accept()" class="btn-siguiente col-6 popup" style="
                            background-color: #263245;
                            border-color: #263245;
                            color: white !important;
                            font-size: 0.8rem;
                            ">
                        </button>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </p-confirmDialog>
</div>
<div>
    <p-dialog header="Fijar Audiencia" [(visible)]="audienciaDialogVisible" [modal]="true" [closable]="false" >
        <app-audience (closePopup)="cancel($event)"  [claim]="claim" (showMessage)="handleShowMessage($event)" ></app-audience>
    </p-dialog>
      
</div>
<div class="col-12">
    <ng-container *ngIf="!userClaims" class="empty-home-container">
        <div class="flex flex-column justify-content-center align-items-center">
            <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
            <p class="mt-4 text-lg font-normal">Aún no tiene reclamos laborales para visualizar</p>
        </div>
    </ng-container>
</div>
<div *ngIf="userClaims" class="grid w-full relative p-3">
    <p-tabView class="w-full">
        <p-tabPanel class="w-full">
            <ng-template pTemplate="header">
                <div>Reclamos individuales</div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-table (onLazyLoad)="loadRecords($event)" [tableStyle]="{ 'min-width': '100%'}" *ngIf="userClaims" [value]="userClaims"
                    currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}" class="col-12">
                    <ng-template pTemplate="header">
                        <tr class="font-bold text-xs">
                            <th class="col-12 sm:col-6 md:col-2">Reclamante</th>
                            <th style="display: none;" class="col-12 sm:col-6 md:col-2">CUIT/CUIL</th>
                            <th class="col-12 sm:col-6 md:col-2">Reclamado</th>
                            <th style="display: none;" class="col-12 sm:col-6 md:col-2">CUIT/CUIL</th>
                            <th class="col-6 sm:col-3 md:col-1">Nº de reclamo</th>
                            <th class="col-12 sm:col-6 md:col-2">Nº de expte.</th>
                            <th style="display: none;" class="col-6 sm:col-3 md:col-1">Fecha de inicio</th>
                            <th class="col-12 sm:col-6 md:col-2">Conciliador</th>
                            <th class="col-12 sm:col-6 md:col-2">Estado</th>
                            <th class="col-12 sm:col-6 md:col-1"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-claim>
                        <tr class="table-row">
                            <td class="col-12 sm:col-6 md:col-3">
                                <ng-container *ngIf="claim.nameLastNameOrBusinessNameClaimet; else showClaimantName">
                                    {{ claim.nameLastNameOrBusinessNameClaimet }}
                                </ng-container>
                                <ng-template #showClaimantName>
                                    {{ claim.claimentName }} {{ claim.claimentLastName }}
                                </ng-template>
                            </td>
                            <td style="display: none;" class="col-12 sm:col-6 md:col-3">{{ claim.claimentCuil }}</td>
                            <td class="col-12 sm:col-6 md:col-3">
                                <ng-container *ngIf="claim.nameLastNameOrBusinessNameClaimed; else showClaimantName">
                                    {{ claim.nameLastNameOrBusinessNameClaimed }}
                                </ng-container>
                                <ng-template #showClaimantName>
                                    {{ claim.nameLastNameOrBusinessNameClaimed }}
                                </ng-template>
                            </td>
                            <td style="display: none;" class="col-12 sm:col-6 md:col-3">{{ claim.claimedCuil }}</td>
                            <td class="col-6 sm:col-3 md:col-1">{{ claim.numberOfClaim }}</td>
                            <td class="col-12 sm:col-6 md:col-3">{{ claim.proceedings }}</td>
                            <td style="display: none;" class="col-6 sm:col-3 md:col-1">{{ claim.startDate | date: 'dd/MM/yyyy' }}
                            </td>
                            <td *ngIf="isComisionMemberRol || isConciliatorRol || isComisionRol"
                                class="col-12 sm:col-6 md:col-3">{{ claim.conciliatorFullName }}</td>

                            <td *ngIf="isAdministratorRol" class="col-12 sm:col-6 md:col-3">
                                <p-dropdown [options]="conciliators" class="conciliator"
                                (onClick)="dropdownClick(claim)" (onChange)="updateConciliatorCheck($event)" appendTo="body"
                                [placeholder]="claim.conciliatorFullName ? claim.conciliatorFullName : 'No asignado'"
>
                                    <ng-template pTemplate="selectedItem" let-selectedOption>
                                        <span>{{ selectedOption.name }} {{ selectedOption.last_name }}
                                        </span>
                                    </ng-template>
                                    <ng-template let-state pTemplate="item">
                                        <span class="px-3 conciliator">{{ state.name }} {{ state.last_name }}
                                        </span>
                                    </ng-template>
                                </p-dropdown>
                            </td>

                            <td *ngIf="isComisionMemberRol || isComisionRol" class="col-12 sm:col-6 md:col-3">
                                <p [ngClass]="getStatusClass(claim.status)">{{ claim.status | claimStatusDisplay}}</p>
                            </td>
                            <td *ngIf="isAdministratorRol || isConciliatorRol" class="status col-12 sm:col-6 md:col-3">
                                <p-dropdown [options]="status " [ngClass]="getStatusClassForPrincipalView(claim.status)"
                                    [placeholder]="claim.status | claimStatusDisplay" (onClick)="dropdownClick(claim)"
                                    (onChange)="updateStatusCheck($event)" appendTo="body">
                                    <ng-template pTemplate="selectedItem" let-selectedOption>
                                        <span [ngClass]="getStatusClass(selectedOption ?? claim.status)">{{
                                            claim.status }}
                                        </span>
                                    </ng-template>
                                    <ng-template let-state pTemplate="item">
                                        <span class="px-3" [ngClass]="getStatusClass(state)">{{ state | claimStatusDisplay }}
                                        </span>
                                    </ng-template>
                                </p-dropdown>
                            </td>

                            <td class="col-12 sm:col-6 md:col-1 ">
                                <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                                <button type="button" pButton icon="pi pi-ellipsis-v"
                                    (click)="selectMenuItemsByRol(claim.status); menu.toggle($event); selectClaim(claim.id)"
                                    class="p-button-rounded p-button-text"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-paginator *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first" [rows]="defaultRows"
                    [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
                </p-paginator>
            </ng-template>
        </p-tabPanel>

        <p-tabPanel class="w-full">
            <ng-template pTemplate="header">
                <div>Reclamos plurindividuales</div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>

        </p-tabPanel>
        <p-tabPanel class="w-full">
            <ng-template pTemplate="header">
                <div>Reclamos colectivos</div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>

        </p-tabPanel>
    </p-tabView>
</div>

import { Failure } from "src/app/core/utils/failure";
import { CompanyEntity } from "../entities/company.entity";
import { CompanyPaginationParams } from "../entities/company-pagination-params";
import { CompanyPagination } from "../entities/company-pagination";
import { DeleteCompanyEntity } from "../entities/delete-company.entity";

export abstract class CompanyrepositoryBase {
    abstract getCompany(id: string): Promise<CompanyEntity | Failure>;
    abstract getPaginatedCompanies(params: CompanyPaginationParams): Promise<CompanyPagination | Failure>;
    abstract createCompany(company:CompanyEntity): Promise<string | Failure>;
    abstract updateCompany(company:CompanyEntity): Promise<string | Failure>;
    abstract deleteCompany(deleteCompany:DeleteCompanyEntity): Promise<string | Failure>;

}
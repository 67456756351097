<form [formGroup]="registerFormTwoImputs" (ngSubmit)="nextStep()" class="w-8 sm:w-8 md:w-8 lg:w-7" id="container-form">

    <h2>Crear cuenta</h2>
    <p>{{selectedRol}}</p>

    <ng-container *ngIf="selectedRol === 'Trabajador'">

        <div class="grid mt-1">
            <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                <label for="company">Nombre de la empresa*</label>
                <input pInputText id="company" aria-describedby="username-help"
                    placeholder="Ingrese su empresa empleadora" formControlName="company" maxlength="100"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('company', 'required')}" />
                <div class="text-danger p-error" *ngIf="hasErrors('company', 'required')">
                    Este campo es obligatorio
                </div>
            </div>
        </div>

        <div class="grid mt-1">
            <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                <label for="company_cuil">CUIT de la empresa empleadora*</label>
                <p-inputMask mask="99-99999999-9" placeholder="99-99999999-9" formControlName="company_cuil" [ngClass]="{
                  'ng-invalid ng-dirty': hasErrors('company_cuil', 'required')
                }"></p-inputMask>
                <div class="text-danger p-error" *ngIf="hasErrors('company_cuil', 'required')">
                    Este campo es obligatorio
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedRol === 'Representante gremial'">

        <div *ngIf="selectedeDoYouAct === 'Delegado de la empresa'">
            <div class="grid mt-1">
                <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                    <label for="company">Empresa donde cumple su rol *</label>
                    <input pInputText id="company" aria-describedby="username-help"
                        placeholder="Ingrese el nombre o razón social de la empresa" formControlName="company"
                        maxlength="100" [ngClass]="{'ng-invalid ng-dirty': hasErrors('company', 'required')}" />
                    <div class="text-danger p-error" *ngIf="hasErrors('company', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
            </div>

            <div class="grid mt-1">
                <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                    <label for="company_cuil">CUIT de la empresa *</label>
                    <p-inputMask mask="99-99999999-9" placeholder="99-99999999-9" formControlName="company_cuil"
                        [ngClass]="{
                          'ng-invalid ng-dirty': hasErrors('company_cuil', 'required')
                        }"></p-inputMask>
                    <div class="text-danger p-error" *ngIf="hasErrors('company_cuil', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="selectedeDoYouAct === 'Miembro de comision directiva'">

            <div class="grid mt-1">
                <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                    <label for="comission_position">Cargo que ocupa en la Comisión Directiva *</label>
                    <input pInputText id="comission_position" aria-describedby="username-help"
                        placeholder="Ingrese el cargo que ocupa" formControlName="comission_position" maxlength="100"
                        [ngClass]="{'ng-invalid ng-dirty': hasErrors('comission_position', 'required')}" />
                    <div class="text-danger p-error" *ngIf="hasErrors('comission_position', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
            </div>

            <div class="grid mt-1">
                <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                    <label for="comission_email">Correo electrónico *</label>
                    <input pInputText id="comission_email" type="email" maxlength="40" autocomplete="off"
                        placeholder="Ingrese su correo electronico" formControlName="comission_email" [ngClass]="{'ng-invalid ng-dirty':
                        hasErrors('comission_email', 'required') || hasErrors('comission_email', 'email')}" />
                    <div class="text-danger p-error" *ngIf="hasErrors('comission_email', 'required')">
                        Este campo es obligatorio
                    </div>
                    <div class="text-danger p-error" *ngIf="hasErrors('comission_email', 'email')">
                        Formato de email incorrecto
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <p-button class="nextButton" label="Siguiente" [disabled]="registerFormTwoImputs.invalid" type="submit"></p-button>
    <span class="backLink"><a (click)="previusStep()">Volver</a></span>

</form>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Rol } from 'src/app/core/interfaces/user.interface';

interface RolOptions {
  name:string;
  icon: string;
  justify: string;
  rol: Rol
}

@Component({
  selector: 'app-step-rol',
  templateUrl: './step-rol.component.html',
  styleUrls: ['./step-rol.component.css']
})
export class StepRolComponent {

  @Output() rol : EventEmitter<FormGroup> = new EventEmitter(); 
  @Input() generalForm: FormGroup = this.formBuilder.group({});  

  rolOptions: RolOptions[] = [
    { name: 'Trabajador', icon: "pi pi-user", justify: "left", rol: Rol.Trabajador },
    { name: 'Empleador', icon: "pi pi-warehouse", justify: "left", rol: Rol.Empleador},
    { name: 'Representante gremial', icon: "pi pi-users", justify: "left", rol: Rol['Representante gremial']}
   ];

  registerFormRol: FormGroup = this.formBuilder.group({
    rol: [ , Validators.required]
  })

  constructor( private formBuilder: FormBuilder,
               private router: Router){}

  nextStep(){
    if (this.registerFormRol.invalid) return;
    this.registerFormRol.patchValue(this.registerFormRol);
    this.rol.emit(this.registerFormRol.value);       
  }

  goToLogin(){
    this.router.navigate(['login'])
  }
}

import { Entity } from "src/app/core/utils/entity";

export abstract class CreateAudienceEntity extends Entity {
    constructor(
        public date: string,
        public time: string,
        public audienceTitle: string,
        public claimetId: string,
        public claimedId: string,
        public claimId: string,       
        public linkGoogleMeet?: string,        
    ) {
        super();
    }
}
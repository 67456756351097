<div class="">
    <p-toast />
    <p-confirmDialog #cd>
        <ng-template pTemplate="headless" let-message>
            <div class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round ">
                <span class="col-12 font-semibold text-xl ">
                    {{ message.header }}
                </span>
                <div class="col-12 flex align-items-center justify-content-center gap-3">
                    <i class="pi pi-exclamation-circle text-lg"></i>
                    <p class="">{{ message.message }}</p>
                </div>
                <div class="col-12 flex gap-3 justify-content-end">
                    <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar " style="border-color: #263245;
                    background-color: white;
                    color: #263245;
                    font-size: 0.8rem;">
                    </button>
                    <button pButton label="Eliminar empresa" (click)="cd.accept()" class="btn-siguiente " style="
                        background-color: #C93B3B;
                        border-color: #C93B3B;
                        font-size: 0.8rem;">
                    </button>
                </div>
            </div>
        </ng-template>
    </p-confirmDialog>
</div>

<div *ngIf="!companyList" class="col-12">
    <ng-container class="empty-home-container">
        <div class="flex flex-column align-items-center">
            <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
            <p>Aún no se han añadido empresas. Agregue una a continuación.</p>
            <p-button class="btn-new-claim" label="Nueva empresa" icon="pi pi-plus"
                routerLink="/main/newCompany"></p-button>
        </div>
    </ng-container>
</div>

<div  *ngIf="companyList" class="flex flex-column w-full h-full gap-3 p-4">
    <div class="nuevoReclamo">
        <div *ngIf="!homeView" class="flex flex-column p-3">
            <p class="font-semibold text-xl title">Empresas</p>
            <div class="flex grid  w-full align-items-center justify-content-between">
                <div class="busqueda flex col-4">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText placeholder="Buscar" class="inputBuscador" />
                    </span>
                    <p-button class="btn-filter ml-2" icon="pi pi-sliders-h"></p-button>
                </div>
                <div class="col-3 text-right">
                    <p-button class="btn-new-claim" label="Nuevo empresa" icon="pi pi-plus"
                        routerLink="/main/newCompany"></p-button>
                </div>
            </div>
        </div>

        <div class="grid w-full relative p-3">
            <p-table (onLazyLoad)="loadRecords($event)" [tableStyle]="{ 'min-width': '100%'}" [value]="companyList"
                currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}" class="col-12">
                <ng-template pTemplate="header">
                    <tr class="font-semibold text-xs">
                        <th class="col-6 sm:col-3 md:col-3">Razon Social</th>
                        <th class="col-6 sm:col-3 md:col-2">CUIT</th>
                        <th class="col-12 sm:col-6 md:col-3">Correo electónico</th>
                        <th class="col-12 sm:col-6 md:col-3">Localidad</th>
                        <th class="col-6 sm:col-3 md:col-2">Teléfono</th>
                        <th class="col-12 sm:col-6 md:col-1"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-company>
                    <tr class="table-row">
                        <td class="col-6 sm:col-3 md:col-1">{{ company.razon_social }}</td>
                        <td class="col-6 sm:col-3 md:col-1">{{ company.cuit }}</td>
                        <td class="col-12 sm:col-6 md:col-3">{{ company.email }}</td>
                        <td class="col-12 sm:col-6 md:col-3">{{ company.city }}</td>
                        <td class="col-6 sm:col-3 md:col-1">{{ company.phone }}</td>
                        <td class="col-12 sm:col-6 md:col-1 text-right">
                            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                            <button type="button" pButton icon="pi pi-ellipsis-v"
                                (click)="menu.toggle($event); selectcompany(company.id)"
                                class="p-button-rounded p-button-text"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator class="col-12" *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first"
                [rows]="defaultRows" [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
            </p-paginator>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { ClaimServiceService } from 'src/app/features/claims/services/claim-service.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-simple-rol-home',
  templateUrl: './simple-rol-home.component.html',
  styleUrls: ['./simple-rol-home.component.css']
})
export class SimpleRolHomeComponent implements OnInit{
  tableOrImg: boolean = false;
  @Input() userData?: UserData;
  rol: string | null = localStorage.getItem('user_rol');
  claimsQuantity = localStorage.getItem('claims_quantity');
  claims = this.claimsQuantity !== null && parseInt(this.claimsQuantity) > 0;
  activeClaims: boolean = false;

  constructor(
    private userService: UserService,
    private claimService: ClaimServiceService) { }
  
  ngOnInit(): void {
  }

  getUserData() : void {
    this.userService.userData$.subscribe(userData => {      
      if(!userData) return;
      this.userData = userData;
      this.rol = this.userData?.rol;      
    });
  }

  getClaimsData(): void {
    this.claimService.claims$.subscribe(claims => {
      if(!claims) return;
    })
  }

  isActiveClaims(event: any): void {
    this.activeClaims = event;
  }
}
